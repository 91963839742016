<template>
    <vertical-form-table v-model="sender" mode="mask" v-bind="{ items }" @input="emit" />
</template>

<script>
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const items = [
    {
        key: "name",
        term: "이름",
        type: "text",
        required: true,
    },
    {
        key: "phone",
        term: "연락처",
        type: "phone",
        required: true,
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: true,
    },
].map((item) => ({ ...item, outlined: true }));

const initSender = (sender = {}) => ({
    ...sender,
    name: sender?.name || null,
    phone: sender?.phone || null,
    email: sender?.email || null,
});

export default {
    components: { VerticalFormTable },
    props: {
        value: { type: Object, default: initSender },
    },
    data: () => ({
        items,
        sender: initSender(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.sender = initSender(this.value);
        },
        emit() {
            this.$emit("input", this.sender);
        },
    },
};
</script>

<style></style>
