<template>
    <u-dialog v-model="shows" title="쿠폰 사용하기" width="1080" maxWidth="1080" eager v-bind="{ loading }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs: { ...attrs, loading, disabled }, count_total: coupons.length, count_usable: coupons.length, on }" />
        </template>
        <v-row class="mx-n3 my-3">
            <v-col cols="8" class="pa-3">
                <v-divider class="grey darken-4" style="border-width: 2px 0 0 !important" />
                <form-table v-model="selected" v-bind="{ items, order: value, loading }" />
            </v-col>
            <v-col cols="4" class="pa-3">
                <order-coupons-prices v-bind="{ carts, selected }" :order="value" />
            </v-col>
        </v-row>

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="btn_tertiary" block class="v-size--xx-large" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="btn_primary" block class="v-size--xx-large" @click="save">저장하기</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";

import { mapState } from "vuex";
import { btn_primary, btn_tertiary, initOrder, USAGE_TARGET_PRICES } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import FormTable from "@/components/client/shop/coupons/form/form-table.vue";
import OrderCouponsPrices from "./order-coupons-prices.vue";

export default {
    components: {
        UDialog,
        FormTable,
        OrderCouponsPrices,
    },
    props: {
        value: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
    },
    data: () => ({
        coupons: [],
        selected: [],

        btn_primary,
        btn_tertiary,

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapState(["accessToken"]),
        items() {
            return [...(this.coupons || [])].map(this.makeItem);
        },
        disabled() {
            return !this.loading && !this.items?.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        accessToken() {
            this.init();
        },
    },
    methods: {
        sync() {
            const orderCoupons = this.value.coupons.filter((coupon) => coupon.usage.target.price == USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value);

            this.selected = orderCoupons.map(this.makeItem);
        },

        emit() {
            const coupons = this.value.coupons.filter((coupon) => coupon.usage.target.price != USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value).concat(...this.selected);
            this.$emit("input", initOrder({ ...this.value, coupons }));
        },

        makeItem(coupon) {
            const otherCoupons = this.value.coupons.filter((coupon) => coupon.usage.target.price != USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value);

            let couponPrice = this.$getCouponPrice(this.carts, [...[...otherCoupons, coupon].filter((item) => item)]);
            couponPrice -= this.$getCouponPrice(this.carts, otherCoupons);

            return { ...coupon, couponPrice };
        },

        async init() {
            if (!this.accessToken) {
                this.coupons = [];
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupons } = await api.v1.me.coupons.gets({ params: { ["usage.target.price"]: USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value } });

                this.coupons = coupons;
            } finally {
                this.loading = false;
                this.emit();
            }
        },

        async validate() {
            const uncombinableCoupons__this = this.selected.filter((coupon) => !coupon?.usage?.combinable);

            if (0 < uncombinableCoupons__this.length) {
                if (1 < this.selected.length) {
                    alert("중복사용 불가 쿠폰은 단독으로만 사용할 수 있습니다.");
                    this.loading = false;
                    return false;
                }

                const otherCoupons__order = this.value.coupons.filter((coupon) => coupon?.usage?.target?.price != USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value);

                if (0 < otherCoupons__order.length) {
                    const go = confirm("이미 사용중인 다른 쿠폰이 있습니다.\r\n지금 선택하신 쿠폰으로 변경하시겠습니까?");

                    if (!go) {
                        alert("취소하셨습니다.");
                        this.loading = false;
                        return false;
                    }

                    for (const coupon of otherCoupons__order) {
                        const { _id } = coupon;
                        switch (coupon?.usage?.target?.price) {
                            case USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value: {
                                await api.v1.me.coupons.putShippingCode({ _id, shippingCode: null });
                                break;
                            }
                            case USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value: {
                                await api.v1.me.coupons.putProduct({ _id, _product: null });
                                break;
                            }
                        }
                    }
                }
            }

            return true;
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            if (!(await this.validate())) return;

            try {
                this.loading = false;
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
                this.emit();
            }
        },
    },
};
</script>

<style></style>
