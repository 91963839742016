<template>
    <slide-primary :slideNum="1">
        <template v-for="item in items">
            <swiper-slide :key="item._id">
                <product-list-item :value="item" slide />
            </swiper-slide>
        </template>
    </slide-primary>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { DISPLAY_CODES, initProduct } from "@/assets/variables";

import SlidePrimary from "@/components/dumb/slide-primary.vue";
import ProductListItem from "@/components/client/shop/products/list/product-list-item.vue";

export default {
    components: {
        SlidePrimary,
        ProductListItem,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        products: [],
        loading: false,
    }),
    computed: {
        ...mapState(["accessToken"]),
        items() {
            return this.products;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        accessToken() {
            this.init();
        },
        "product._id"() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (!this.product?._id) return;

            if (this.loading) return;
            else this.loading = true;

            this.products = [];
            try {
                const makeParams = () => ({
                    _id: { $nin: [this.product?._id, ...this.products.map(({ _id }) => _id)].filter((item) => item) },
                });

                // AI 추천 제품 (제품전시 NEW_ARRIVAL)
                const { products: products__displayed } = await api.v1.shop.products.gets({
                    headers: { skip: 0, limit: 8 },
                    params: { ...makeParams(), groups: { $in: [DISPLAY_CODES.NEW_ARRIVAL.value] } },
                });
                this.products = [...products__displayed];
                if (8 <= this.products.length) throw new Error();

                // 제품구매 수량 및 금액 순서
                if (this.accessToken) {
                    const { products: products__purchased } = await api.v1.shop.products.gets({
                        headers: { skip: 0, limit: 8 - this.products.length },
                        params: { ...makeParams(), byPurchaseCount: true },
                    });
                    this.products = [...this.products, ...products__purchased];
                    if (8 <= this.products.length) throw new Error();
                }

                // 제품샘플
                const { products: products__sampled } = await api.v1.shop.products.gets({
                    headers: { skip: 0, limit: 8 - this.products.length, sample: true },
                    params: makeParams(),
                });
                this.products = [...this.products, ...products__sampled];
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
