<template>
    <client-page class="app--product">
        <page-section class="page-section--first page-section--last">
            <!-- S: 제품 개요 -->
            <section class="product-intro">
                <!-- S: 제품 이미지 -->
                <v-row>
                    <v-col cols="12" sm="6" class="mb-14px mb-sm-0">
                        <product-view-imgs v-bind="{ product }" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <product-view-info v-bind="{ product }">
                            <template #form>
                                <!-- <product-view-coupons v-bind="{ product }" /><select name="" id=""></select> -->
                                <product-form-related-group-items v-bind="{ product }" />
                                <info-section class="txt txt--xs">
                                    <product-form-options v-model="carts" v-bind="{ product }" />
                                    <product-form-carts v-model="carts" :clearable="isProductCartClearable" />
                                </info-section>
                                <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                            </template>

                            <template #actions>
                                <product-form-price v-bind="{ carts }" />

                                <div class="btn-wrap">
                                    <v-row class="row--xs ma-md-n6px">
                                        <v-col cols="12" sm="4" class="pa-md-6px">
                                            <inquire-form v-bind="{ product }">
                                                <template #activator="{ on }">
                                                    <v-btn v-bind="{ ...btn_secondary, loading }" block v-on="on" class="v-size--xx-large">제품문의</v-btn>
                                                </template>
                                            </inquire-form>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pa-md-6px">
                                            <v-btn v-bind="{ ...btn_tertiary, loading }" block class="v-size--xx-large" @click="addToCart">장바구니</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="4" class="pa-md-6px">
                                            <v-btn v-bind="{ ...btn_primary, loading }" block class="v-size--xx-large" @click="buyNow">바로구매</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </template>

                            <!-- <template #foot>
                                <product-view-coupons v-bind="{ product }" class="d-lg-none" />
                                <div class="btn-wrap">
                                    <naver-pay disabled button-key="E8FAC592-0582-4E64-B0A7-410385FFD5AA" :carts="carts" :_product="product?._id" />
                                </div>
                            </template> -->
                        </product-view-info>
                    </v-col>
                </v-row>
            </section>
            <!-- E: 제품 개요 -->

            <page-section class="pb-0">
                <tit-wrap-small :title="`${user?.name || '회원'}님이 가장 많이 구매하시는 제품`" />
                <shop-product-ai v-bind="{ product }" />
            </page-section>

            <!-- <page-section class="page-section--sm pb-0">
                <tit-wrap-small title="추가 제품 선택" />
                <shop-product-addons v-bind="{ product }" />
            </page-section> -->

            <!-- S: 제품 상세 -->
            <section class="product-details">
                <product-view-nav v-bind="{ product, summary }">
                    <template #form>
                        <product-form-related-group-items v-bind="{ product }" />
                        <product-form-options v-model="carts" v-bind="{ product }" />
                        <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                    </template>
                    <template #actions>
                        <product-form-carts v-model="carts" :clearable="isProductCartClearable" />
                        <product-form-price v-bind="{ carts }" small />

                        <div class="btn-wrap">
                            <v-row class="row--xs ma-md-n6px">
                                <v-col cols="12" sm="4" class="pa-md-6px">
                                    <inquire-form v-bind="{ product }">
                                        <template #activator="{ on }">
                                            <v-btn v-bind="{ ...btn_secondary, loading }" block v-on="on">제품문의</v-btn>
                                        </template>
                                    </inquire-form>
                                </v-col>
                                <v-col cols="12" sm="4" class="pa-md-6px">
                                    <v-btn v-bind="{ ...btn_tertiary, loading }" block @click="addToCart">장바구니</v-btn>
                                </v-col>
                                <v-col cols="12" sm="4" class="pa-md-6px">
                                    <v-btn v-bind="{ ...btn_primary, loading }" block @click="buyNow">바로구매</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </product-view-nav>

                <!-- <product-view-reviews v-bind="{ product }" @setSummaryReviews="(count) => (summary.reviews = count)" /> -->

                <div id="detail" class="product-details__content pt-20px pt-md-40px">
                    <section class="product-sub" v-html="product?.content"></section>
                </div>

                <!-- <product-view-inquires :product="product" @setSummaryInquires="(count) => (summary.inquires = count)" /> -->

                <product-view-others />
            </section>
            <!-- E: 제품 상세 -->

            <!-- S: 모바일하단 고정영역 -->
            <product-view-mobile-bottom-sheet>
                <template #form>
                    <product-form-related-group-items v-bind="{ product }" />
                    <product-form-options v-model="carts" v-bind="{ product }" />
                    <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                    <product-form-carts v-model="carts" :clearable="isProductCartClearable" />
                </template>

                <template #actions>
                    <product-form-price v-bind="{ carts }" />
                    <div class="btn-wrap">
                        <v-row class="row--sm">
                            <v-col cols="6" class="pa-md-6px">
                                <inquire-form v-bind="{ product }">
                                    <template #activator="{ on }">
                                        <v-btn v-bind="{ ...btn_secondary, loading }" block v-on="on" class="v-size--xx-large">제품문의</v-btn>
                                    </template>
                                </inquire-form>
                            </v-col>
                            <v-col cols="6">
                                <v-btn v-bind="{ ...btn_tertiary, ...$attrs, loading }" block class="v-size--xx-large" @click="addToCart">장바구니</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-btn v-bind="{ ...btn_primary, ...$attrs, loading }" block class="v-size--xx-large" @click="buyNow"><span class="white--text">바로구매</span></v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </product-view-mobile-bottom-sheet>
            <!-- E: 모바일하단 고정영역 -->

            <product-form-modal-cart ref="modal-cart" />
        </page-section>

        <product-view-console />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions, mapMutations, mapState } from "vuex";
import { btn_primary, btn_secondary, btn_tertiary } from "@/assets/variables";
import { initProduct, initShopShippingOption, PRODUCT_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import InfoSection from "@/components/client/shop/products/view/info/info-section.vue";

import NaverPay from "@/components/plugins/naver/naver-pay.vue";

import ProductViewNav from "@/components/client/shop/products/view/product-view-nav.vue";
import ProductViewImgs from "@/components/client/shop/products/view/product-view-imgs.vue";
import ProductViewInfo from "@/components/client/shop/products/view/product-view-info.vue";
import ProductViewOthers from "@/components/client/shop/products/view/product-view-others.vue";
import ProductViewReviews from "@/components/client/shop/products/view/product-view-reviews.vue";
import ProductViewInquires from "@/components/client/shop/products/view/product-view-inquires.vue";
import ProductViewMobileBottomSheet from "@/components/client/shop/products/view/product-view-mobile-bottom-sheet.vue";

import ProductFormCarts from "@/components/client/shop/products/form/product-form-carts.vue";
import ProductFormPrice from "@/components/client/shop/products/form/product-form-price.vue";
import ProductFormOptions from "@/components/client/shop/products/form/product-form-options.vue";
import ProductFormShipping from "@/components/client/shop/products/form/product-form-shipping.vue";
import ProductFormModalCart from "@/components/client/shop/products/form/product-form-modal-cart.vue";
import ProductFormRelatedGroupItems from "@/components/client/shop/products/form/product-form-related-group-items.vue";

import ShopProductAi from "@/components/client/shop/shop-product-ai.vue";
import ShopProductAddons from "@/components/client/shop/shop-product-addons.vue";

import ProductViewCoupons from "@/components/client/shop/products/view/product-view-coupons.vue";

import InquireForm from "@/components/client/shop/inquires/inquire-form.vue";
import ProductViewConsole from "@/components/client/shop/products/view/product-view-console.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapSmall,
        InfoSection,

        NaverPay,

        ProductViewNav,
        ProductViewImgs,
        ProductViewInfo,
        ProductViewOthers,
        ProductViewReviews,
        ProductViewInquires,
        ProductViewMobileBottomSheet,

        ProductFormCarts,
        ProductFormPrice,
        ProductFormOptions,
        ProductFormShipping,
        ProductFormModalCart,
        ProductFormRelatedGroupItems,

        ShopProductAi,
        ShopProductAddons,

        ProductViewCoupons,

        InquireForm,
        ProductViewConsole,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_tertiary,

        product: initProduct(),

        summary: {
            reviews: 0,
            inquires: 0,
        },

        carts: [],

        shippingOption: null,

        loading: false,
    }),
    computed: {
        ...mapState(["user", "watches", "shipping__everyAsset"]),
        shippingOptions() {
            return (this.product?.shipping || this.shipping__everyAsset)?.options || [];
        },
        isProductCartClearable() {
            return this.product.type == PRODUCT_TYPES.VARIETY_BUNDLE.value;
        },
        seo() {
            return this.product?.seo;
        },
    },
    metaInfo() {
        return { ...this.seo };
    },
    mounted() {
        this.init();
    },
    watch: {
        _product() {
            this.init();
        },
    },
    methods: {
        ...mapActions(["addToWatch"]),
        ...mapMutations(["setWatches"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            this.summary = { reviews: 0, inquires: 0 };
            this.carts = [];
            this.shippingOption = null;

            try {
                const { product } = await api.v1.shop.products.get({ _id: this._product });
                this.product = product;

                const isVarietyBundle = product?.type == PRODUCT_TYPES.VARIETY_BUNDLE.value;
                const isProductBundle = product?.type == PRODUCT_TYPES.PRODUCT_BUNDLE.value;
                const isOptionEnabled = product?.optionsConf?.enabled;

                if (!isVarietyBundle && (isProductBundle || !isOptionEnabled)) {
                    this.$set(this.carts, 0, {
                        _product: product._id,
                        product,
                        name: "본품",
                        price: product.price,
                        salePrice: product.salePrice,
                        discountPrice: product.discountPrice,
                        stock: product.stock,
                        stockEnabled: product.stockEnabled,
                        amount: 1,
                    });
                }

                if (this.shippingOptions.length == 1) this.shippingOption = this.shippingOptions[0];

                const _watches = (this.$store?.state?.watches || []).map(({ _id }) => _id);
                const watches = (await api.v1.me.watches.gets({ params: { _watches } }))?.watches;
                this.setWatches({ watches });

                let watch = watches.find(({ _product }) => _product == product._id);

                if (!watch) {
                    watch = (await api.v1.me.watches.post({ _product: product._id }))?.watch;
                } else {
                    watch = (await api.v1.me.watches.put({ _id: watch._id, createdAt: Date.now() }))?.watch;
                }
                this.addToWatch([watch]);
            } finally {
                this.loading = false;
            }
        },

        ////////////////////////////////////////////////////////////////////////
        // 장바구니 담기
        ////////////////////////////////////////////////////////////////////////
        async addToCart() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.carts.length) throw new Error("구매하실 제품을 선택해주세요");
                if (!!this.shippingOptions.length && !this.shippingOption?._id) throw new Error("배송받을 방법을 선택해주세요");

                var soldOut = this.carts.find((cart) => cart.stockEnabled && cart.stock == 0);
                if (soldOut) throw new Error("품절된 제품입니다");

                const { shippingOption } = this;
                const { carts } = await api.v1.me.carts.post({
                    carts: this.carts,
                    shippingOption,
                });
                this.$store.dispatch("addToCart", carts);

                this.$refs["modal-cart"].open();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },

        ////////////////////////////////////////////////////////////////////////
        // 바로구매
        ////////////////////////////////////////////////////////////////////////
        async buyNow() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.carts.length) throw new Error("구매하실 제품을 선택해주세요");
                if (!this.shippingOption?._id) throw new Error("배송받을 방법을 선택해주세요");

                var soldOut = this.carts.find((cart) => cart.stockEnabled && cart.stock == 0);
                if (soldOut) throw new Error("품절된 제품입니다");

                const { shippingOption } = this;
                const { carts } = await api.v1.me.carts.post(
                    {
                        carts: this.carts,
                        shippingOption,
                    },
                    { headers: { mode: "buy" } }
                );
                this.$store.dispatch("addToCart", carts);

                const _carts = carts.map(({ _id }) => _id);
                this.$store.dispatch("pickup", _carts);
                this.$router.push({ path: "/shop/order" });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .theme--light.v-bottom-navigation .v-btn.primary--text:not(.v-btn--active) {
        color: var(--v-primary-base) !important;
    }
}
</style>
