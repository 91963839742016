var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vertical-form-table', _vm._b({
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "배송지선택",
      fn: function () {
        return [_c('v-radio-group', {
          attrs: {
            "row": "",
            "hide-details": ""
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.receiver.type,
            callback: function ($$v) {
              _vm.$set(_vm.form.receiver, "type", $$v);
            },
            expression: "form.receiver.type"
          }
        }, [_vm.defaultReceiver ? [_c('v-radio', _vm._b({
          attrs: {
            "value": "default",
            "label": "기본배송지"
          }
        }, 'v-radio', Object.assign({}, _vm.radio_secondary), false))] : _vm._e(), _c('v-radio', _vm._b({
          attrs: {
            "value": "new-one",
            "label": "신규배송지"
          }
        }, 'v-radio', Object.assign({}, _vm.radio_secondary), false)), _vm.anotherReceiver ? [_c('v-radio', _vm._b({
          attrs: {
            "value": "another",
            "label": "다른배송지"
          }
        }, 'v-radio', Object.assign({}, _vm.radio_secondary), false)), _c('destination-select-dialog', {
          on: {
            "input": _vm.emit
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "v-size--xx-small"
              }, 'v-btn', Object.assign({}, _vm.btn_tertiary, attrs), false), on), [_vm._v("배송지 목록")])];
            }
          }], null, false, 3817738915),
          model: {
            value: _vm.form.receiver,
            callback: function ($$v) {
              _vm.$set(_vm.form, "receiver", $$v);
            },
            expression: "form.receiver"
          }
        })] : _vm._e()], 2)];
      },
      proxy: true
    }, {
      key: "기공소",
      fn: function () {
        return [_c('v-checkbox', {
          staticClass: "ma-0 pa-0",
          attrs: {
            "disabled": _vm.mode != 'form',
            "hide-details": ""
          },
          on: {
            "change": _vm.emit
          },
          scopedSlots: _vm._u([_vm.mode == 'form' ? {
            key: "label",
            fn: function () {
              return [_vm._v("보내는 곳이 기공소입니다")];
            },
            proxy: true
          } : {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.form.receiver.isFactory ? "보내는 곳이 기공소입니다" : "보내는 곳이 기공소가 아닙니다") + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form.receiver.isFactory,
            callback: function ($$v) {
              _vm.$set(_vm.form.receiver, "isFactory", $$v);
            },
            expression: "form.receiver.isFactory"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items,
    mode: _vm.mode
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }