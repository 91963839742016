<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">주문하기</template>

            <!-- S: 장바구니 -->
            <page-section class="pt-0">
                <template slot="containerImmersive">
                    <order-carts v-model="order" v-bind="{ carts }" ref="order-carts" />
                </template>
            </page-section>
            <!-- E: 장바구니 -->

            <div class="fixed-section">
                <div class="fixed-contents">
                    <v-row class="row--contents flex-nowrap">
                        <!-- S: contents -->
                        <v-col cols="12" xl="" class="flex-grow-1 flex-shrink-1">
                            <div class="mw-xl-100">
                                <!-- S: 구매자정보 -->
                                <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <tit-wrap-line noDivider title="구매자 정보" />
                                        <order-sender v-model="order.sender" @input="emit" />
                                    </template>
                                </page-section>
                                <!-- E: 구매자정보 -->

                                <!-- S: 배송지정보 -->
                                <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <tit-wrap-line noDivider title="배송지 정보" />

                                        <order-receiver v-model="order" @input="emit" />
                                    </template>
                                </page-section>
                                <!-- E: 배송지정보 -->

                                <!-- S: 요청사항 -->
                                <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <tit-wrap-line noDivider title="요청사항" />
                                        <vertical-form-table v-model="order" :items="[{ key: 'requestMessage', term: '요청사항', type: 'textarea', ...attrs_input, placeholder: '요청사항을 입력하세요' }]" @input="emit" />
                                    </template>
                                </page-section>
                                <!-- E: 요청사항 -->

                                <!-- S: 쿠폰할인 -->
                                <!-- <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <tit-wrap-line noDivider title="쿠폰할인" />
                                        <order-coupons v-model="order" v-bind="{ carts }" @input="updateCoupons__carts" />
                                    </template>
                                </page-section> -->
                                <!-- E: 쿠폰할인 -->

                                <!-- S: 적립금사용 -->
                                <!-- <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <tit-wrap-line noDivider title="적립금사용">
                                            <span class="d-flex align-center txt txt--sm">
                                                <span class="pr-10px pr-md-16px">보유 적립금</span>
                                                <span class="grey--text text--darken-4">
                                                    <strong class="txt--lg">{{ user?.point?.format?.() || 0 }}</strong> 원
                                                </span>
                                            </span>
                                        </tit-wrap-line>
                                        <order-point v-model="order.point" :max="maxUsablePoint" @input="emit" />
                                    </template>
                                </page-section> -->
                                <!-- E: 적립금사용 -->

                                <!-- S: 결제방법 -->
                                <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <tit-wrap-line noDivider title="결제방법" />

                                        <v-simple-table class="v-data-table--default v-data-table--no-hover">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <v-radio-group v-model="order.paymentMethod" row hide-details @input="emit">
                                                            <!-- <v-radio v-bind="{ ...radio_secondary, ...$attrs }" name="payment_method" value="card" label="신용카드" /> -->
                                                            <v-radio v-bind="{ ...radio_secondary, ...$attrs }" name="payment_method" value="easy" label="간편결제" />
                                                            <v-radio v-bind="{ ...radio_secondary, ...$attrs }" name="payment_method" value="account" label="무통장 입금" v-if="user?.scope?.includes?.('console')" />
                                                            <v-radio v-bind="{ ...radio_secondary, ...$attrs }" name="payment_method" value="advance" label="선수금" />
                                                        </v-radio-group>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </template>
                                </page-section>
                                <!-- E: 결제방법 -->

                                <!-- S: 간편결제 정보 -->
                                <v-expand-transition>
                                    <page-section class="page-section--sm pt-0 pb-xl-0" v-show="order.paymentMethod == 'easy'">
                                        <template slot="containerImmersive">
                                            <!-- head -->
                                            <tit-wrap-line noDivider title="간편결제 본인인증" align="baseline">
                                                <v-subheader class="error--text pa-0 my-n3" v-if="!user?.nicepayUserName"> ※ 결제에 사용하실 카드의 명의자 정보를 입력해주세요 </v-subheader>
                                            </tit-wrap-line>
                                            <order-easy v-model="order" @input="emit" />
                                        </template>
                                    </page-section>
                                </v-expand-transition>
                                <!-- E: 간편결제 정보 -->

                                <!-- S: 선수금 사용 -->
                                <v-expand-transition>
                                    <page-section class="page-section--sm pt-0 pb-xl-0" v-show="order.paymentMethod == 'advance'">
                                        <template slot="containerImmersive">
                                            <!-- head -->
                                            <tit-wrap-line noDivider title="선수금 사용">
                                                <span class="d-flex align-center txt txt--xs">
                                                    <span class="pr-10px pr-md-16px">보유 선수금</span>
                                                    <span class="primary--text d-inline-flex align-center">
                                                        <strong class="tit tit--xs pr-2px">{{ user?.money?.format?.() || 0 }}</strong> 원
                                                    </span>
                                                </span>
                                            </tit-wrap-line>
                                            <order-money v-model="order.money" :max="maxUsableMoney" @input="emit" />
                                        </template>
                                    </page-section>
                                </v-expand-transition>
                                <!-- E: 선수금 사용 -->

                                <!-- Mobile -->
                                <!-- S: 결제정보 -->
                                <page-section class="page-section--last page-section--sm d-xl-none pt-0 pb-xl-0">
                                    <template slot="containerImmersive">
                                        <v-card class="mt-xl-12px">
                                            <div>
                                                <tit-wrap-line noDivider title="결제정보" />

                                                <v-divider class="grey darken-4" style="border-width: 2px 0 0 !important" />
                                                <div class="py-16px px-20px border-b">
                                                    <v-row align="center" class="row--xs txt txt--xs">
                                                        <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                            <span class="txt--dark">제품금액</span>
                                                        </v-col>
                                                        <v-col cols="7" sm="9" md="10" xl="7">
                                                            <span> {{ productPrice.format() }} 원 </span>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <div class="py-16px px-20px border-b" v-if="discountPrice">
                                                    <v-row align="center" class="row--xs txt txt--xs">
                                                        <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                            <span class="txt--dark">제품할인</span>
                                                        </v-col>
                                                        <v-col cols="7" sm="9" md="10" xl="7">
                                                            <span> {{ discountPrice.format() }} 원 </span>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <div class="py-16px px-20px border-b">
                                                    <v-row align="center" class="row--xs txt txt--xs">
                                                        <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                            <span class="txt--dark">배송비</span>
                                                        </v-col>
                                                        <v-col cols="7" sm="9" md="10" xl="7">
                                                            <span> {{ (servicePrice + deliveryPrice).format() }} 원 </span>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <div class="py-16px px-20px border-b" v-if="islandPrice">
                                                    <v-row align="center" class="row--xs txt txt--xs">
                                                        <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                            <span class="txt--dark">추가배송비</span>
                                                        </v-col>
                                                        <v-col cols="7" sm="9" md="10" xl="7">
                                                            <span> {{ islandPrice.format() }} 원 </span>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <!-- <div class="py-16px px-20px border-b">
                                                    <v-row align="center" class="row--xs txt txt--xs">
                                                        <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                            <span class="txt--dark">사용적립금</span>
                                                        </v-col>
                                                        <v-col cols="7" sm="9" md="10" xl="7">
                                                            <span> {{ order?.point?.format?.() }} 원 </span>
                                                        </v-col>
                                                    </v-row>
                                                </div> -->
                                                <div class="py-16px px-20px border-b">
                                                    <v-row align="center" class="row--xs txt txt--xs">
                                                        <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                            <span class="txt--dark">사용선수금</span>
                                                        </v-col>
                                                        <v-col cols="7" sm="9" md="10" xl="7">
                                                            <span>{{ order?.money?.format?.() }} 원</span>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <!-- <div class="py-16px px-20px border-b">
                                                    <v-row align="center" class="row--xs txt txt--xs">
                                                        <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                            <span class="txt--dark">쿠폰할인</span>
                                                        </v-col>
                                                        <v-col cols="7" sm="9" md="10" xl="7">
                                                            <span> {{ couponPrice.format() }} 원 </span>
                                                        </v-col>
                                                    </v-row>
                                                </div> -->
                                                <div class="py-16px px-20px border-b">
                                                    <v-row align="center" class="row--xs txt txt--xs txt--dark">
                                                        <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                                                            <span>총 결제금액</span>
                                                        </v-col>
                                                        <v-col cols="7" sm="9" md="10" xl="7">
                                                            <span class="d-inline-flex flex-wrap align-center">
                                                                <strong class="font-size-18 font-size-md-20">{{ totalPrice.format() }}</strong>
                                                                <span class="pl-4px">원</span>
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                </div>

                                                <div class="pt-16px">
                                                    <v-row class="row--xs">
                                                        <!-- <v-col cols="12" sm="6" lg="12">
                                                <v-btn block outlined color="primary" to="/" class="v-size--xx-large" v-bind="{ loading }">취소하기</v-btn>
                                            </v-col> -->
                                                        <v-col cols="12">
                                                            <v-btn block color="grey darken-4" class="v-size--xx-large" v-bind="{ loading }" @click="pay">결제하기</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </div>
                                        </v-card>
                                    </template>
                                </page-section>
                                <!-- E: 결제정보 -->
                            </div>
                        </v-col>
                        <!-- E: contents -->

                        <!-- PC -->
                        <!-- S: 사이드 영역 -->
                        <v-col cols="auto" class="d-none d-xl-block">
                            <div class="fixed-contents__fixed">
                                <v-card elevation="4" min-width="282" class="txt txt--xs">
                                    <div class="pa-24px">
                                        <h4 class="tit tit--xs mb-16px">결제 정보</h4>
                                        <v-divider />
                                        <v-row no-gutters align="center" class="py-12px">
                                            <v-col class="min-w-80px pl-12px">
                                                <span>제품금액</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="txt--dark font-weight-medium"> {{ productPrice.format() }} 원 </span>
                                            </v-col>
                                        </v-row>
                                        <v-divider />
                                        <v-row no-gutters align="center" class="py-12px">
                                            <v-col class="min-w-80px pl-12px">
                                                <span>할인금액</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="txt--dark font-weight-medium"> {{ discountPrice.format() }} 원 </span>
                                            </v-col>
                                        </v-row>
                                        <v-divider />
                                        <v-row no-gutters align="center" class="py-12px">
                                            <v-col class="min-w-80px pl-12px">
                                                <span>배송비</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="txt--dark font-weight-medium"> {{ (servicePrice + deliveryPrice).format() }} 원 </span>
                                            </v-col>
                                        </v-row>
                                        <template v-if="islandPrice">
                                            <v-divider />
                                            <v-row no-gutters align="center" class="py-12px">
                                                <v-col class="min-w-80px pl-12px">
                                                    <span>추가배송비</span>
                                                </v-col>
                                                <v-col class="text-right">
                                                    <span class="txt--dark font-weight-medium"> {{ islandPrice.format() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <v-divider />
                                        <!-- <v-row no-gutters align="center" class="py-12px">
                                            <v-col class="min-w-80px pl-12px">
                                                <span>사용적립금</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="txt--dark font-weight-medium"> {{ order?.point?.format?.() }} 원 </span>
                                            </v-col>
                                        </v-row> -->
                                        <!-- <v-divider /> -->
                                        <v-row no-gutters align="center" class="py-12px">
                                            <v-col class="min-w-80px pl-12px">
                                                <span>사용선수금</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="txt--dark font-weight-medium">{{ order?.money?.format?.() }} 원</span>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-divider /> -->
                                        <!-- <v-row no-gutters align="center" class="py-12px">
                                            <v-col class="min-w-80px pl-12px">
                                                <span>쿠폰할인</span>
                                            </v-col>
                                            <v-col class="text-right">
                                                <span class="txt--dark font-weight-medium"> {{ couponPrice.format() }} 원 </span>
                                            </v-col>
                                        </v-row> -->
                                        <v-divider />
                                        <v-row no-gutters align="center" class="py-16px">
                                            <v-col class="min-w-80px pl-12px">
                                                <span>총 결제금액</span>
                                            </v-col>
                                            <v-col class="text-right" style="word-wrap: break-word; word-break: break-all">
                                                <span class="primary--text d-inline-flex flex-wrap align-center justify-end">
                                                    <strong class="tit tit--sm">{{ totalPrice.format() }}</strong>
                                                    <span class="txt txt--sm pl-2px">원</span>
                                                </span>
                                            </v-col>
                                        </v-row>

                                        <v-btn v-bind="{ loading }" color="primary" block class="v-size--xx-large" @click="pay">결제</v-btn>
                                    </div>
                                </v-card>
                            </div>
                            <div class="fixed-contents__fixed-con"></div>
                        </v-col>
                        <!-- E: 사이드 영역 -->
                    </v-row>
                </div>
            </div>
        </page-section>

        <v-fade-transition>
            <v-overlay v-show="loading" color="white">
                <v-progress-circular indeterminate size="100" width="5" color="primary" />
            </v-overlay>
        </v-fade-transition>

        <!-- 무통장 입금 모달 -->
        <modal-order-bank ref="modal-order-bank" v-model="order" @pay="submit" :totalPrice="totalPrice" @input="emit" />

        <!-- 쿠폰 사용 -->
        <!-- <div>
            쿠폰 사용 (제품 / 주문쿠폰)
            <input type="number" onkeydown="return event.keyCode !== 69" :value="couponPrice" />
            <span class="currency-unit">원</span>
            <button class="button button--mouse" type="button" @click="openModalUseCoupon">
                <span>쿠폰사용</span>
            </button>
            <span class="left-text">사용가능 쿠폰 : {{ $refs["modal-use-coupon"] ? $refs["modal-use-coupon"].coupons.length : 0 }}장</span>
        </div> -->

        <!-- 쿠폰사용 모달 -->
        <!-- <modal-use-coupon ref="modal-use-coupon" v-model="coupons" :carts="carts" :deliveryPrice="deliveryPrice"> </modal-use-coupon> -->

        <order-geolocation v-model="order.receiver.geolocation" :address="order.receiver.address1" @input="emit" />

        <nice-pay ref="nicePay" />
        <nice-payu ref="nicePayu" @complete="(payload) => complete(payload)" />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions, mapState } from "vuex";
import { radio_secondary } from "@/assets/variables";

import detect from "@/plugins/detect.js";
import { attrs_input } from "@/assets/variables";
import { initOrder } from "@/assets/variables/inits";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

import NicePay from "@/components/plugins/nice/nice-pay.vue";
import NicePayu from "@/components/plugins/nice/nice-payu.vue";

import OrderEasy from "@/components/client/shop/order/order-easy.vue";
import OrderCarts from "@/components/client/shop/order/order-carts/order-carts.vue";
import OrderPoint from "@/components/client/shop/order/order-point.vue";
import OrderMoney from "@/components/client/shop/order/order-money.vue";
import OrderSender from "@/components/client/shop/order/order-sender.vue";
import OrderCoupons from "@/components/client/shop/order/order-coupons/order-coupons.vue";
import OrderReceiver from "@/components/client/shop/order/order-receiver.vue";
import OrderGeolocation from "@/components/client/shop/order/order-geolocation.vue";
// import DestinationSelectDialog from "@/components/client/shop/destinations/destination-select-dialog.vue";

import ModalOrderBank from "../../../components/client/shop/modal-order-bank.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapLine,
        VerticalFormTable,

        NicePay,
        NicePayu,

        OrderEasy,
        OrderCarts,
        OrderPoint,
        OrderMoney,
        OrderSender,
        OrderCoupons,
        OrderReceiver,
        OrderGeolocation,
        // DestinationSelectDialog,

        ModalOrderBank,
    },

    data: () => ({
        radio_secondary,

        detect,

        loading: false,

        pickup: { _carts: [] },
        carts: [],

        order: initOrder(),
        attrs_input,
    }),

    computed: {
        ...mapState(["user", "islands", "setting"]),

        ///////////////////////////////////////////////////////////////////////
        // 액세스 토큰
        ///////////////////////////////////////////////////////////////////////
        accessToken() {
            return this.$store.state.accessToken;
        },

        ///////////////////////////////////////////////////////////////////////
        // 우편번호
        ///////////////////////////////////////////////////////////////////////
        postcode() {
            return this.order.receiver.postcode;
        },

        ///////////////////////////////////////////////////////////////////////
        // 배송가능지역
        ///////////////////////////////////////////////////////////////////////
        isShippingAvailable() {
            return this.$getIsShippingAvailable(this.carts, this.order?.receiver, this.setting);
        },

        ///////////////////////////////////////////////////////////////////////
        // 제품가 합계
        ///////////////////////////////////////////////////////////////////////
        productPrice() {
            return this.$getProductPrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 설치서비스 합계
        ///////////////////////////////////////////////////////////////////////
        servicePrice() {
            return this.$getServicePrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 할인가 합계
        ///////////////////////////////////////////////////////////////////////
        discountPrice() {
            return this.$getDiscountPrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 쿠폰할인금액 합계
        ///////////////////////////////////////////////////////////////////////
        couponPrice() {
            return this.$getCouponPrice(this.carts, this.order.coupons);
        },

        ///////////////////////////////////////////////////////////////////////
        // 배송비 합계
        ///////////////////////////////////////////////////////////////////////
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.order?.receiver, this.setting);
        },

        ///////////////////////////////////////////////////////////////////////
        // 도서산간비용 합계
        ///////////////////////////////////////////////////////////////////////
        islandPrice() {
            return this.$getIslandPrice(this.carts, this.islands, this.postcode);
        },

        ///////////////////////////////////////////////////////////////////////
        // 최대사용가능포인트
        ///////////////////////////////////////////////////////////////////////
        maxUsablePoint() {
            const priceLeft = this.productPrice + this.servicePrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.couponPrice || 0;
            const pointLeft = this.user?.point || 0;
            return [priceLeft, pointLeft].sort((a, b) => a - b).shift();
        },

        ///////////////////////////////////////////////////////////////////////
        // 최대사용가능선수금
        ///////////////////////////////////////////////////////////////////////
        maxUsableMoney() {
            return this.productPrice + this.servicePrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.couponPrice || 0;
        },

        ///////////////////////////////////////////////////////////////////////
        // 총 결제 금액
        ///////////////////////////////////////////////////////////////////////
        totalPrice() {
            return this.productPrice + this.servicePrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.couponPrice - this.order.point - this.order.money;
        },
    },
    mounted() {
        this.reroute().then(this.init);
    },
    watch: {
        accessToken() {
            this.reroute();
        },
        isShippingAvailable() {
            if (!this.isShippingAvailable) {
                alert("해당 주소로는 주문할 수 없는 배송이 있습니다.");
            }
        },
    },
    methods: {
        ...mapActions(["getUser", "getIslands", "getShipping__everyAsset"]),

        openModalUseCoupon() {
            this.$refs["modal-use-coupon"].open();
        },

        openModalOrderBank() {
            this.$refs["modal-order-bank"].open();
        },

        emit() {
            this.order = initOrder(this.order);
        },

        async reroute() {
            try {
                if (!this.accessToken) throw new Error("로그인 후 사용하실 수 있습니다");
            } catch (error) {
                this.$handleError(error);
                this.$router.replace("/login");
            }
        },

        async init() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.$store.state.pickup.length) {
                    alert("구매할 제품을 선택해주세요");
                    this.$router.go(-1);
                    return;
                }

                const _carts = this.$store.state.pickup;
                this.order._carts = _carts;

                const { carts } = await api.v1.me.carts.gets({ params: { _carts } });
                this.carts = carts;

                await api.plugins.nice.payu.userKey.get();

                this.getUser().then(() => this.$nextTick(() => (this.order = initOrder({ ...this.order, sender: { ...(this.order?.sender || {}), ...(this.user || {}) } }))));

                this.getIslands();
                this.getShipping__everyAsset();

                this.$nextTick(() => {
                    setTimeout(() => {
                        this.onScrollFixed();
                    }, 500);
                });
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async validate() {
            if (this.order.receiver.type == "new-one") {
                if (!this.order?.receiver?.name) throw new Error("수취인명을 입력해주세요");
                if (!this.order?.receiver?.phone) throw new Error("수취인 연락처를 입력해주세요");
                if (!this.order?.receiver?.address2) throw new Error("수취인 주소를 입력해주세요");
                if (!this.order?.receiver?.title) throw new Error("배송지명을 입력해주세요");
            }

            // const hasAnUncombinableCoupon = this.order?.coupons?.some?.((coupon) => !coupon?.usage?.combinable) || false;
            // const isCouponsCountMoreThan1 = 1 < this.order?.coupons?.length || 0;
            // if (hasAnUncombinableCoupon && isCouponsCountMoreThan1) throw new Error("중복사용이 불가능한 쿠폰이 있습니다.");

            if (this.order.paymentMethod == "advance") {
                if (this.totalPrice != 0) throw new Error("선수금은 전액결제로만 사용할 수 있습니다");
            }
            if (this.order.paymentMethod == "easy") {
                const paymentUserNull = [this.order.paymentUserName, this.order.paymentUserPhone].some((item) => !item);
                if (paymentUserNull) throw new Error("간편결제 본인인증 정보를 입력하세요");
            }
        },

        async pay() {
            if (!this.isShippingAvailable) {
                alert("해당 주소로는 주문할 수 없는 배송이 있습니다.");
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                await this.validate();

                if (this.order.receiver.type == "new-one") {
                    const { receiver } = this.order || {};

                    const { destination } = await api.v1.me.destinations.post(receiver);
                    this.order.receiver = destination;
                    this.order.receiver.type = "another";
                }

                if (this.order.paymentMethod == "easy") {
                    await api.v1.me.put({
                        nicepayUserName: this.order.paymentUserName,
                        nicepayUserPhone: this.order.paymentUserPhone,
                    });
                }

                switch (this.order.paymentMethod) {
                    case "account": {
                        this.openModalOrderBank();
                        break;
                    }
                    case "card": {
                        this.submit();
                        break;
                    }
                    case "easy": {
                        this.submit();
                        break;
                    }
                    case "advance": {
                        this.submit();
                    }
                }
            } finally {
                this.loading = false;
            }
        },

        complete(payload) {
            if (payload == "closed") this.$router.go();
            else this.$router.push({ path: "/shop/result", query: payload });
        },

        async submit() {
            try {
                let { ...form } = this.order;

                form._coupons = form.coupons.map(({ _id }) => _id);
                delete form.coupons;

                let { order } = await api.v1.shop.orders.post(form);

                if (order.totalPrice) {
                    switch (order.paymentMethod) {
                        case "account":
                        case "advance": {
                            order = (await api.v1.shop.orders.process.post(order))?.order;
                            this.$router.push({ path: "/shop/result", query: { _order: order?._id } });
                            break;
                        }
                        case "card": {
                            this.$refs.nicePay.amt = order.totalPrice;
                            this.$refs.nicePay.goodsName = this.carts[0].product.name;
                            this.$refs.nicePay.moid = order.orderNo;
                            this.$refs.nicePay.buyerTel = order.sender.phone;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.nicepayStart();
                            break;
                        }
                        case "easy": {
                            this.$refs.nicePayu.orderName = this.carts[0].product.name;
                            this.$refs.nicePayu.orderNo = order.orderNo;
                            this.$refs.nicePayu.paymentAmount = order.totalPrice;
                            this.$refs.nicePayu.userKey = order.paymentUserKey || null;
                            this.$refs.nicePayu.buyerName = order.paymentUserName;
                            this.$refs.nicePayu.buyerEmail = order.sender.email;
                            this.$refs.nicePayu.buyerPhone = order.paymentUserPhone;
                            this.$refs.nicePayu.buyerUsername = order.sender.username;
                            this.$refs.nicePayu.receiverName = order.receiver.name;
                            this.$refs.nicePayu.receiverPhone = order.receiver.phone;
                            this.$refs.nicePayu.receiverPostcode = order.receiver.postcode;
                            this.$refs.nicePayu.receiverAddress1 = order.receiver.address1;
                            this.$refs.nicePayu.receiverAddress2 = order.receiver.address2;

                            this.$refs.nicePayu.open();
                            break;
                        }
                    }
                } else {
                    order = (await api.v1.shop.orders.process.post(order))?.order;
                    this.$router.push({ path: "/shop/result", query: { _order: order?._id } });
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async updateCoupons__carts() {
            if (this.loading) return;
            else this.loading = true;

            this.emit();

            this.$nextTick(async () => {
                try {
                    await this.$refs["order-carts"].emit();
                } finally {
                    this.loading = false;
                }
            });
        },

        onScrollFixed() {
            // Sticky
            let scrollPosition = 0,
                absoluteTop = 0,
                ticking = false,
                mobileWidth = 1024,
                gridGutter = 10,
                fixedContentsFixedPaddingTop = 12;
            let timeout;
            if (window.innerWidth >= 1800) {
                gridGutter = 30;
            } else if (window.innerWidth >= 1600) {
                gridGutter = 20;
            } else if (window.innerWidth >= 1400) {
                gridGutter = 14;
            } else {
                gridGutter = 10;
            }

            var fixedSection = document.querySelector(".fixed-section");
            var fixedContentsFixed = document.querySelector(".fixed-contents__fixed");
            var fixedContentsFixedCon = document.querySelector(".fixed-contents__fixed-con");
            var fixedSectionTop = fixedSection.getBoundingClientRect().top;
            var fixedSectionHeight = fixedSection.clientHeight - gridGutter * 2;
            var fixedContentsFixedHeight = fixedContentsFixed.clientHeight;
            var fixedSectionBottom = fixedSectionTop + fixedSectionHeight - fixedContentsFixedHeight;
            var fixedContentsFixedTranslate = fixedSectionHeight - fixedContentsFixedHeight;

            function animate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                absoluteTop = window.pageYOffset + fixedSection.getBoundingClientRect().top + fixedContentsFixedPaddingTop;

                // PC
                if (window.innerWidth > mobileWidth) {
                    if (scrollPosition + 112 < absoluteTop) {
                        fixedSection.classList.remove("active");
                    } else {
                        fixedSection.classList.add("active");
                    }

                    if (scrollPosition + 50 > fixedSectionBottom) {
                        fixedSection.classList.add("bottom");
                        fixedContentsFixedCon.style.minHeight = fixedContentsFixedHeight + "px";
                        fixedContentsFixed.style.transform = "translate(0px," + fixedContentsFixedTranslate + "px)";
                    } else {
                        fixedSection.classList.remove("bottom");
                        fixedContentsFixed.style = "";
                    }
                }
            }

            animate();

            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        animate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            window.addEventListener("resize", resize, false);
            function resize() {
                if (!timeout) {
                    timeout = setTimeout(function () {
                        timeout = null;
                        handle();
                    }, 66);
                }
            }
            function handle() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    animate();
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fixed-contents {
    &__fixed-con {
        display: none;
        height: 100%;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .fixed-section {
        position: relative;
        display: block;
        &.active {
            .fixed-contents {
                &__fixed {
                    position: fixed;
                    top: 112px;
                    & + .fixed-contents__fixed-con {
                        display: block;
                    }
                }
            }
        }
        &.bottom {
            .fixed-contents {
                &__fixed {
                    position: absolute !important;
                    top: auto !important;
                    left: auto !important;
                }
                &__fixed-con {
                    display: block;
                }
            }
        }
    }
    .fixed-contents {
        position: relative;
        &__fixed,
        &__fixed-con {
            width: 290px;
        }
    }
}
</style>
