<template>
    <v-text-field v-model="searchValue" dense outlined hide-details @focus="focus = true" @keydown.enter="search" clearable @click:clear="clear">
        <template #prepend-inner v-if="!focus">
            <v-btn text icon tile class="mt-n2 ml-n2">
                <icon-search class="icon-search--sm" />
            </v-btn>
        </template>
        <template #append v-if="focus">
            <v-btn text icon tile class="mt-n2 mr-n2" @click="search">
                <icon-search class="icon-search--sm" />
            </v-btn>
        </template>
    </v-text-field>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import IconSearch from "@/components/client/icons/icon-search.vue";

export default {
    components: {
        IconSearch,
    },
    data: () => ({
        // searchKey: "name",
        searchValue: null,

        attrs_input,

        focus: false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const {
                // searchKey,
                searchValue,
            } = this.$route?.query || {};
            // this.searchKey = searchKey || "name";
            this.searchValue = searchValue;
        },
        search() {
            const {
                //  searchKey,
                searchValue,
            } = this;

            const query = {
                // searchKey,
                searchValue,
            };
            // if (!searchKey) delete query.searchKey;
            if (!searchValue) delete query.searchValue;

            this.focus = false;
            this.$router.push({
                path: `/shop/products`,
                query,
            });
        },
        clear() {
            this.searchValue = null;
            this.search();
        },
    },
    watch: {
        "$route.query.searchKey"() {
            this.init();
        },
        "$route.query.searchValue"() {
            this.init();
        },
    },
};
</script>
