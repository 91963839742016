var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__content",
    attrs: {
      "id": "review"
    }
  }, [_c('div', {
    staticClass: "product-review"
  }, [_c('div', {
    staticClass: "pb-20px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("제품평 (" + _vm._s(_vm.summary.totalCount) + ")")])])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "py-20px py-md-32px"
  }, [_c('review-list-summary', _vm._b({}, 'review-list-summary', {
    product: _vm.product
  }, false))], 1), _c('review-images', {
    attrs: {
      "_product": _vm.$route.params._product
    }
  }), _c('div', {
    staticClass: "border-b"
  }, _vm._l(_vm.reviews, function (review) {
    return _c('review-list-item', {
      key: review._id,
      attrs: {
        "value": review
      }
    });
  }), 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }