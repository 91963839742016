var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--contents flex-xl-nowrap"
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-200px w-lg-240px"
  }, [_c('product-list-aside', {
    attrs: {
      "categoryCode": _vm.$route.query.category
    }
  })], 1)]) : _vm._e(), _c('v-col', [_c('div', {
    staticClass: "mb-20px mb-lg-40px"
  }, [_c('product-list-ahead', _vm._b({
    attrs: {
      "hideInfo": _vm.mode == 'category'
    }
  }, 'product-list-ahead', {
    category: _vm.category,
    summary: _vm.summary
  }, false))], 1), _vm.mode == 'products' ? _c('section', {
    staticClass: "product-lists"
  }, [_c('div', {
    staticClass: "product-lists-body"
  }, [_c('product-list-items', _vm._g(_vm._b({}, 'product-list-items', _vm.productsListProp, false), {
    setLoading: _vm.setLoading,
    setSummary: _vm.setSummary
  })), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "value": _vm.page,
      "count": _vm.pageCount
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)]) : _vm._e(), _vm.mode == 'category' ? _c('v-sheet', [_vm._l(_vm.categoryListProp, function (_ref, index) {
    var category = _ref.category,
      props = _ref.props;
    return [_c('section', {
      key: index,
      staticClass: "product-lists"
    }, [_c('div', {
      class: {
        'mb-n24px mb-md-n40px': index == _vm.categoryListProp.length - 1
      }
    }, [_c('div', {
      staticClass: "pb-24px pb-md-40px pt-0"
    }, [_c('tit-wrap-line', {
      staticClass: "mb-12px mb-md-20px",
      attrs: {
        "title": category.name
      }
    }, [_c('btn-primary', {
      attrs: {
        "to": {
          query: {
            category: category.code
          }
        }
      }
    }, [_vm._v(" More ")])], 1), _c('product-list-items', _vm._b({}, 'product-list-items', props, false))], 1)])])];
  })], 2) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }