var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "product-items"
  }, _vm._l(_vm.products, function (product, index) {
    return _c('product-list-item', {
      key: index,
      attrs: {
        "value": product
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }