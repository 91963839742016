<template>
    <!-- 총 제품 금액 -->
    <page-section class="page-section--xs pb-0">
        <v-row align="center">
            <v-col cols="auto">
                <div class="txt txt--xs">총 제품 금액</div>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <span class="d-flex align-center line-height-1 grey--text text--darken-4">
                    <strong class="tit " :class="small ? 'tit--xs' : 'tit--sm'">{{ totalPrice.format() }}</strong>
                    <span class="txt txt--dark pl-2px" :class="small ? 'txt--xs' : 'txt--sm'">원</span>
                </span>
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    props: {
        carts: { type: Array, default: () => [] },
        small : { type: Boolean, default: false },
    },
    computed: {
        totalPrice() {
            return this.carts.reduce((totalPrice, cart) => {
                return totalPrice + cart.salePrice * cart.amount;
            }, 0);
        },
    },
};
</script>

<style>
</style>