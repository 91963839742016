<template>
    <v-data-table v-model="selected" v-bind="{ headers, items, loading }" show-select single-select hide-default-footer @click:row="(item, { isSelected, select }) => select(!isSelected)" item-key="_id" sort-by="couponPrice" sort-desc hide-default-header @input="emit">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>
        <template #[`item.coupon`]="{ item }">
            <item-chip :coupon="item" />
            <item-name :coupon="item" />
            <item-price :coupon="item" />
            <item-desc :coupon="item" mode="user-coupons" class="font-size-12 grey--text" />
            <item-usage :coupon="item" v-bind="{ order }" />
        </template>
    </v-data-table>
</template>

<script>
import { initOrder } from "@/assets/variables";
import itemChip from "../item/item-chip.vue";
import ItemDesc from "../item/item-desc.vue";
import ItemName from "../item/item-name.vue";
import ItemPrice from "../item/item-price.vue";
import ItemUsage from "../item/item-usage.vue";

const headers = [
    { text: "할인금액", value: "couponPrice", formatter: (value) => (value ? `${value?.format?.() || value}원` : "-") },
    { text: "쿠폰", value: "coupon" },
];

export default {
    components: {
        itemChip,
        ItemName,
        ItemPrice,
        ItemDesc,
        ItemUsage,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        items: { type: Array, default: () => [] },
        order: { type: Object, default: initOrder },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        headers,

        selected: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.selected = [...(this.value || [])];
        },
        emit() {
            this.$emit("input", this.selected);
        },
    },
};
</script>

<style>
</style>