<template>
    <div>
        <v-divider class="grey darken-4" style="border-width: 2px 0 0" />
        <div class="py-12px pa-md-16px">
            <filter-search />
        </div>
        <v-divider />
        <div class="px-md-16px py-10px">
            <filter-categories v-bind="{ brand }" :code="categoryCode" @search="$emit('search')" />
        </div>
        <!-- <v-divider />
        <div class="px-md-16px py-20px py-md-30px">
            <filter-prices />
        </div> -->
        <v-divider />
    </div>
</template>

<script>
import FilterSearch from "./filter/filter-search.vue";
import FilterCategories from "./filter/filter-categories.vue";
// import FilterPrices from "./filter/filter-prices.vue";

export default {
    components: {
        FilterSearch,
        FilterCategories,
        // FilterPrices,
    },
    props: {
        brand: { type: Object },
        categoryCode: { type: String },
    },
};
</script>

<style></style>
