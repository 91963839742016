var render = function render(){
  var _vm$category, _vm$category2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('tit-wrap-subtitle', {
    staticClass: "mb-0",
    attrs: {
      "titAlign": "text-center text-md-left"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_c('span', {
    staticClass: "tit"
  }, [_vm.$route.query.searchValue ? [_vm._v(" " + _vm._s(_vm.$route.query.searchValue) + " ")] : (_vm$category = _vm.category) !== null && _vm$category !== void 0 && _vm$category.name ? [_vm._v(" " + _vm._s((_vm$category2 = _vm.category) === null || _vm$category2 === void 0 ? void 0 : _vm$category2.name) + " ")] : [_vm._v(" Total ")]], 2)]), _c('template', {
    slot: "subTit"
  }, [_vm.$route.query.searchValue ? [_vm._v(" 키워드검색 ")] : _vm._e()], 2)], 2)], 1), !_vm.hideInfo ? [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "font-size-14 grey--text"
  }, [_vm._v(" 검색결과 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.summary.totalCount.format()))]), _vm._v(" 개 ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('product-list-sort-select')], 1), _vm.$vuetify.breakpoint.smAndDown ? _c('v-col', {
    staticClass: "d-md-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('product-list-aside', {
    attrs: {
      "categoryCode": _vm.$route.query.category
    }
  })], 1) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }