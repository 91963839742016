<template>
    <vertical-form-table v-model="form" v-bind="{ items, mode }" @input="emit">
        <template #배송지선택>
            <v-radio-group v-model="form.receiver.type" row hide-details @change="emit">
                <template v-if="defaultReceiver">
                    <v-radio v-bind="{ ...radio_secondary }" value="default" label="기본배송지" />
                </template>
                <v-radio v-bind="{ ...radio_secondary }" value="new-one" label="신규배송지" />
                <template v-if="anotherReceiver">
                    <v-radio v-bind="{ ...radio_secondary }" value="another" label="다른배송지" />
                    <destination-select-dialog v-model="form.receiver" @input="emit">
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" class="v-size--xx-small">배송지 목록</v-btn>
                        </template>
                    </destination-select-dialog>
                </template>
            </v-radio-group>
        </template>
        <template #기공소>
            <v-checkbox v-model="form.receiver.isFactory" :disabled="mode != 'form'" hide-details class="ma-0 pa-0" @change="emit">
                <template #label v-if="mode == 'form'">보내는 곳이 기공소입니다</template>
                <template #label v-else> {{ form.receiver.isFactory ? "보내는 곳이 기공소입니다" : "보내는 곳이 기공소가 아닙니다" }} </template>
            </v-checkbox>
        </template>
    </vertical-form-table>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input } from "@/assets/variables/attrs";
import { initDestination, initOrder, initOrderReceiver } from "@/assets/variables/inits";
import { btn_tertiary, radio_secondary, checkbox_secondary } from "@/assets/variables/attrs";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import DestinationSelectDialog from "../destinations/destination-select-dialog.vue";

export default {
    components: {
        VerticalFormTable,
        DestinationSelectDialog,
    },
    props: {
        value: { type: Object, default: initOrder },
    },
    data: () => ({
        btn_tertiary,
        radio_secondary,
        checkbox_secondary,

        form: initOrder(),

        defaultReceiver: undefined,
        anotherReceiver: undefined,

        attrs_input,
    }),
    computed: {
        ...mapState(["accessToken"]),
        type() {
            return this.form.receiver.type || "default";
        },
        mode() {
            switch (this.type) {
                case "new-one": {
                    return "form";
                }
                default: {
                    return "view";
                }
            }
        },
        items() {
            const items = [
                {
                    term: "배송지선택",
                },
                {
                    key: "receiver.title",
                    term: "배송지명",
                    required: true,
                    type: "text",
                },
                {
                    term: "기공소",
                },
                {
                    key: "receiver.name",
                    term: "이름",
                    type: "text",
                    required: true,
                    placeholder: "이름을 입력하세요.",
                },
                {
                    key: "receiver.phone",
                    term: "연락처",
                    type: "phone",
                    required: true,
                },
                {
                    key: "receiver",
                    term: "주소",
                    type: "address",
                    required: true,
                    persistentPlaceholder: true,
                    btnAttrs: { large: true, color: "primary" },
                },
                {
                    key: "receiver.email",
                    term: "이메일",
                    type: "email",
                    required: true,
                    persistentPlaceholder: true,
                    btnAttrs: { large: true, color: "primary" },
                },
                {
                    key: "receiver.isDefault",
                    term: "대표배송지",
                    type: "checkbox",
                    label: "대표배송지로 설정합니다",
                    class: "ma-0 pa-0",
                    hideDetails: true,
                },
            ];
            switch (this.type) {
                case "default": {
                    items.splice(1, 2);
                    items.pop();
                    break;
                }
                case "another": {
                    items.pop();
                }
            }

            return items.map((item) => ({ ...item, outlined: true }));
        },
    },
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        type() {
            switch (this.type) {
                case "default": {
                    this.form = initOrder({ ...this.form, receiver: initOrderReceiver(this.defaultReceiver) });
                    this.emit();
                    break;
                }
                case "new-one": {
                    this.form = initOrder({ ...this.form, receiver: initOrderReceiver({ type: "new-one" }) });
                    this.emit();
                    break;
                }
                case "another": {
                    this.form = initOrder({ ...this.form, receiver: initOrderReceiver(this.anotherReceiver) });
                    this.emit();
                    break;
                }
            }
        },
        accessToken() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                try {
                    const { customer, customerInfo } = await api.v1.dentium.customer.get();
                    this.defaultReceiver = initOrderReceiver({
                        type: "default",
                        title: "기본배송지",
                        name: customer?.FullName,
                        phone: customer?.TellNo || customerInfo?.Tel2,
                        email: customerInfo?.Email,

                        postcode: customerInfo?.ZipCode,
                        address1: customerInfo?.KorAddr1,
                        address2: customerInfo?.KorAddr2,
                    });
                } catch (error) {
                    this.defaultReceiver = null;
                }

                this.anotherReceiver = (await api.v1.me.destinations.gets({ headers: { limit: 1 } }))?.destinations[0] || null;
                if (this.anotherReceiver) this.anotherReceiver.type = "another";

                this.form = initOrder({ ...this.value, receiver: initOrderReceiver(this.defaultReceiver || this.anotherReceiver || { type: "new-one" }) });
                this.emit();
            } finally {
                this.loading = false;
            }
        },

        async sync() {
            this.form = initOrder(this.value);
        },

        async emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
