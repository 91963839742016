<template>
    <v-row align="center" class="row--xs">
        <v-col v-if="!product?.sells || (product?.stockEnabled && product?.stock == 0)" cols="auto">
            <em class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
        </v-col>
        <v-col v-else cols="auto">
            <div class="d-flex align-center font-size-18 font-size-lg-28 font-weight-bold">
                <template v-if="product?.discountPrice">
                    <span class="secondary--text mr-4px mr-md-8px">
                        {{ `${product?.discountRate}%` }}
                    </span>
                </template>
                <span class="d-flex flex-wrap align-center grey--text text--darken-4">
                    {{ product?.salePrice?.format?.() }}
                    <small class="font-size-16 font-weight-regular pl-2px">원</small>
                </span>
            </div>
        </v-col>
        <v-col v-if="product?.discountPrice" cols="auto">
            <span class="font-size-14 grey--text text--lighten-1 text-decoration-line-through">
                {{ product?.price?.format?.() }}
            </span>
        </v-col>
    </v-row>
</template>

<script>
import { initProduct } from "@/assets/variables";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
};
</script>

<style></style>
