var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "배송지 선택하기",
      "width": "1080",
      "maxWidth": "1080"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "dense": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "no-data-text": "등록된 배송지가 없습니다",
      "mobile-breakpoint": "768"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.title`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.isDefault ? _c('div', {
          staticClass: "mb-4px"
        }, [_c('v-chip', {
          attrs: {
            "x-small": "",
            "outlined": "",
            "color": "primary"
          }
        }, [_vm._v("대표배송지")])], 1) : _vm._e(), _c('div', [_vm._v(_vm._s(item.title))]), _c('div', {
          staticClass: "grey--text text--darken-4 font-weight-medium"
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: `item.address`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-left"
        }, [_c('div', [_vm._v("[" + _vm._s(item.postcode) + "]")]), _c('div', [_vm._v(_vm._s(item.address1))]), _c('div', [_vm._v(_vm._s(item.address2))])])];
      }
    }, {
      key: `item.isFactory`,
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_c('v-simple-checkbox', _vm._b({
          attrs: {
            "readonly": ""
          }
        }, 'v-simple-checkbox', {
          value
        }, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0",
          attrs: {
            "cols": "4",
            "md": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "v-size--xx-small",
          attrs: {
            "block": "",
            "outlined": "",
            "color": "grey"
          },
          on: {
            "click": function ($event) {
              return _vm.select(item);
            }
          }
        }, [_vm._v("선택")])], 1)], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false)), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }