<template>
    <v-btn v-if="shows" fixed left bottom x-large color="white" elevation="4" v-bind="{ href }">
        <v-row>
            <v-col> <v-icon>mdi-pencil</v-icon> </v-col>
            <v-col> 제품 수정하기 </v-col>
        </v-row>
    </v-btn>
</template>

<script>
export default {
    computed: {
        shows() {
            return this.$store.state.payload?.scope?.includes?.("console");
        },
        href() {
            const _product = this.$route?.params?._product;
            return `/console/shop/products/${_product}`;
        },
    },
};
</script>

<style></style>
