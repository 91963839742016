<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <v-row class="row--contents flex-xl-nowrap">
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="auto" class="d-none d-md-block">
                    <div class="w-md-200px w-lg-240px">
                        <!-- PC -->
                        <product-list-aside :categoryCode="$route.query.category" />
                    </div>
                </v-col>
                <v-col>
                    <div class="mb-20px mb-lg-40px">
                        <product-list-ahead v-bind="{ category, summary }" :hideInfo="mode == 'category'" />
                    </div>

                    <!-- 제품 리스트 -->
                    <section class="product-lists" v-if="mode == 'products'">
                        <div class="product-lists-body">
                            <product-list-items v-bind="productsListProp" v-on="{ setLoading, setSummary }" />

                            <div class="product-lists__pagination">
                                <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                            </div>
                        </div>
                    </section>

                    <!-- 카테고리별 -->
                    <v-sheet v-if="mode == 'category'">
                        <template v-for="({ category, props }, index) in categoryListProp">
                            <section class="product-lists" :key="index">
                                <div :class="{ 'mb-n24px mb-md-n40px': index == categoryListProp.length - 1 }">
                                    <div class="pb-24px pb-md-40px pt-0">
                                        <tit-wrap-line :title="category.name" class="mb-12px mb-md-20px">
                                            <btn-primary :to="{ query: { category: category.code } }">
                                                More
                                            </btn-primary>
                                        </tit-wrap-line>
                                        <product-list-items v-bind="props" />
                                    </div>                                    
                                </div>
                            </section>
                        </template>
                    </v-sheet>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import { mapState } from "vuex";
import { attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import ProductListItem from "@/components/client/shop/products/list/product-list-item.vue";
import ProductListItems from "@/components/client/shop/products/list/product-list-items.vue";
import ProductListAhead from "@/components/client/shop/products/list/product-list-ahead.vue";
import ProductListAside from "@/components/client/shop/products/list/product-list-aside.vue";
import PaginationComponent from "@/components/client/pagination-component.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapLine,
        ProductListItem,
        ProductListItems,
        ProductListAhead,
        ProductListAside,
        PaginationComponent,
        BtnPrimary,
    },
    metaInfo() {
        return this.seo;
    },
    data: () => ({
        limit: 24,
        summary: { totalCount: 0 },

        loading: false,
        attrs_input,
    }),
    computed: {
        ...mapState(["categories"]),
        category() {
            const categoryCode = this.$route.query.category;
            if (categoryCode) {
                const children = this.categories.flatMap(({ children }) => children);
                const grandChildren = children.flatMap(({ children }) => children);
                const granderChildren = grandChildren.flatMap(({ children }) => children);

                const getCategory = (categories, categoryCode) => categories.find(({ code }) => code == categoryCode);

                return getCategory(this.categories, categoryCode) ?? getCategory(children, categoryCode) ?? getCategory(grandChildren, categoryCode) ?? getCategory(granderChildren, categoryCode);
            }
        },
        searchValue() {
            return this.$route.query.searchValue;
        },
        mode() {
            if (!this.categories.length) return;
            if (this.searchValue) return "products";
            if (this.categoryListProp.length) return "category";
            return "products";
        },
        seo() {
            if (this.category?.seoEnabled) return this.category?.seo;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        productsListProp() {
            const { page, limit } = this;

            let { ...params } = this.$route.query;
            delete params.page;
            delete params.category;
            params._category = this.category?._id;
            if (!params._category) delete params._category;
            params.populate = ["category"];

            return { page, limit, params };
        },
        categoryListProp() {
            const children = this.category?.children || this.categories;
            const items = children.map((category) => ({
                category,
                props: {
                    limit: 4,
                    params: { _category: category._id, populate: ["category"] },
                },
            }));

            return items;
        },
    },
    watch: {
        mode() {
            this.summary = { totalCount: 0 };
        },
    },
    methods: {
        setLoading(loading) {
            this.loading = loading;
        },
        setSummary(summary) {
            this.summary = summary;
        },
    },
};
</script>
