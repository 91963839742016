<template>
    <u-dialog v-model="shows" title="배송지 선택하기" width="1080" maxWidth="1080">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-data-table v-bind="{ items, headers, loading }" :headers="headers" :items="items" dense hide-default-footer disable-sort disable-filtering disable-pagination no-data-text="등록된 배송지가 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile">
            <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
            <template #[`item.title`]="{ item }">
                <div v-if="item.isDefault" class="mb-4px">
                    <v-chip x-small outlined color="primary">대표배송지</v-chip>
                </div>
                <div>{{ item.title }}</div>
                <div class="grey--text text--darken-4 font-weight-medium">{{ item.name }}</div>
            </template>
            <template #[`item.address`]="{ item }">
                <div class="text-left">
                    <div>[{{ item.postcode }}]</div>
                    <div>{{ item.address1 }}</div>
                    <div>{{ item.address2 }}</div>
                </div>
            </template>
            <template #[`item.isFactory`]="{ value }">
                <v-simple-checkbox v-bind="{ value }" readonly />
            </template>
            <template #[`item.actions`]="{ item }">
                <v-row class="row--xxs" justify="center">
                    <v-col cols="4" md="auto" class="mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0">
                        <v-btn block outlined color="grey" class="v-size--xx-small" @click="select(item)">선택</v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-data-table>

        <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
        </div>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { initDestination } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import { mapState } from "vuex";

const headers = [
    { width: 140, align: "center", text: "배송지", value: "title" },
    { width: "auto", align: "center", text: "주소", value: "address" },
    { width: "20%", align: "center", text: "연락처", value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || "-" },
    { width: "20%", align: "center", text: "이메일", value: "email" },
    { width: +80, align: "center", text: "기공소", value: "isFactory" },
    { width: +80, align: "center", text: "관리", value: "actions" },
];

export default {
    components: {
        UDialog,
        VAddressField,
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initDestination },
    },
    data: () => ({
        destinations: [],

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        shows: false,
        loading: false,

        headers,
    }),
    computed: {
        ...mapState(["accessToken"]),
        items() {
            return [...this.destinations].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        page() {
            this.search();
        },
        accessToken() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.destinations = [];
                this.page = 1;

                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit } = this;
                var { summary, destinations } = await api.v1.me.destinations.gets({
                    headers: { skip, limit },
                });

                this.summary = summary;
                this.destinations = destinations;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async select(item) {
            this.$emit("input", { ...item, type: "another" });
            this.shows = false;
        },
    },
};
</script>

<style></style>
