<template>
    <product-groups v-bind="{ product }">
        <template slot-scope="{ groups }">
            <v-row class="row--xxs">
                <template v-for="group in groups">
                    <v-col cols="auto" :key="group.value">
                        <v-chip small outlined color="grey lighten-3" :text-color="group.color" :to="{ path: '/shop/products', query: { groups: group.value } }">{{ group.text }}</v-chip>
                    </v-col>
                </template>
            </v-row>
        </template>
    </product-groups>
</template>

<script>
import { initProduct } from "@/assets/variables";

import ProductGroups from "@/components/client/shop/products/product-groups.vue";

export default {
    components: {
        ProductGroups,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
};
</script>

<style>
</style>