<template>
    <div class="product-intro__info pl-lg-8px">
        <info-section class="mt-sm-n8px">
            <v-row align="end" class="row--xxs">
                <v-col>
                    <!-- 제품 chips -->
                    <info-groups v-bind="{ product }" class="mb-4px mb-md-8px" />
                    <template v-if="product.labels.includes('new')">
                        <v-chip small dark color="#3BAF2C" class="mb-4px mb-md-8px"> new </v-chip>
                    </template>
                    <template v-if="product.labels.includes('digital')">
                        <v-chip small dark color="#8600DD" class="mb-4px mb-md-8px"> digital </v-chip>
                    </template> <br>
                    <template v-if="product?.category?.name">
                        <v-chip small dark :color="product?.category?.color || 'grey'" class="mb-4px mb-md-8px">
                            {{ product?.category?.name }}
                        </v-chip>
                    </template>
                    <h3 class="product-intro__name-text">{{ product.name }}</h3>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <!-- 제품 actions -->
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <info-like v-bind="{ product }" />
                        </v-col>
                        <!-- <v-col cols="auto">
                            <info-share v-bind="{ product }" />
                        </v-col> -->
                    </v-row>
                </v-col>
            </v-row>
        </info-section>

        <!-- <info-section>
            <v-row no-gutters>
                <v-col>
                    <info-flags class="txt txt--sm txt--dark font-weight-bold mb-4px mb-md-8px" v-bind="{ product }" />
                </v-col>
            </v-row>
            <div class="pt-16px pt-md-24px">
                <info-rating v-bind="{ product }" />
            </div>
        </info-section> -->

        <!-- 브랜드 -->
        <info-section class="txt txt--xs" v-if="product?.brand?.label?.url">
            <v-row no-gutters>
                <v-col cols="12" md="auto">
                    <div class="txt--dark font-weight-medium w-md-80px">브랜드</div>
                </v-col>
                <v-col cols="12" md="">
                    <v-img :src="product?.brand?.label?.url" max-width="84" :aspect-ratio="84 / 20" contain />
                </v-col>
            </v-row>
        </info-section>

        <!-- 제품 가격 -->
        <!-- <info-section v-if="product.type != PRODUCT_TYPES.VARIETY_BUNDLE.value">
            <info-price v-bind="{ product }" />
        </info-section> -->

        <!-- 배송정보 -->
        <info-section class="txt txt--xs">
            <info-shipping v-bind="{ product }" />
        </info-section>

        <div class="d-none d-lg-block">
            <slot name="form" />
            <slot name="actions" />
        </div>

        <slot name="foot" />
    </div>
</template>

<script>
import { initProduct, PRODUCT_TYPES } from "@/assets/variables";

import InfoSection from "./info/info-section.vue";

import InfoGroups from "./info/info-groups.vue";
import InfoLike from "./info/info-like.vue";
import InfoShare from "./info/info-share.vue";

import InfoFlags from "./info/info-flags.vue";
import InfoRating from "./info/info-rating.vue";

import InfoPrice from "./info/info-price.vue";
import InfoShipping from "./info/info-shipping.vue";

export default {
    components: {
        InfoSection,

        InfoGroups,
        InfoLike,
        InfoShare,

        InfoFlags,
        InfoRating,

        InfoPrice,
        InfoShipping,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        bottomNav: false,
        PRODUCT_TYPES,
    }),
};
</script>

<style></style>
