<template>
    <v-simple-table class="v-data-table--default v-data-table--no-hover">
        <tbody>
            <tr>
                <td>
                    <v-currency-field v-model="money" v-bind="{ max, messages }" readonly outlined type="number" suffix="원" class="v-currency-field" @input="emit">
                        <template #append-outer>
                            <v-btn x-large outlined color="primary" v-bind="{ disabled }" @click="togglePointMax" class="min-w-md-110px">전체사용</v-btn>
                        </template>
                    </v-currency-field>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        max: { type: Number, default: 0 },
        value: { type: Number, default: 0 },
    },
    data: () => ({
        money: 0,
    }),
    computed: {
        ...mapState(["user"]),
        userMoney() {
            return this.user?.money - this.user?.money__onHold || 0;
        },
        disabled() {
            return !this.user?.scope?.includes?.("credits") && (!this.userMoney || this.userMoney < this.max);
        },
        messages() {
            return `사용가능 선수금 ${this.userMoney.format()}원`;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.money = +this.value || 0;
        },
        emit() {
            this.$emit("input", this.money);
        },
        togglePointMax() {
            if (this.max == this.money) this.money = 0;
            else this.money = this.max;
            this.emit();
        },
    },
};
</script>
