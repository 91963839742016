var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("총 제품 금액")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "d-flex align-center line-height-1 grey--text text--darken-4"
  }, [_c('strong', {
    staticClass: "tit",
    class: _vm.small ? 'tit--xs' : 'tit--sm'
  }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _c('span', {
    staticClass: "txt txt--dark pl-2px",
    class: _vm.small ? 'txt--xs' : 'txt--sm'
  }, [_vm._v("원")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }