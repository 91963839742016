var render = function render(){
  var _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('like-btn', {
    attrs: {
      "params": {
        _product: (_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product._id
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var active = _ref.active,
          toggle = _ref.toggle;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "rounded": "",
            "outlined": "",
            "color": "transparent"
          },
          on: {
            "click": toggle
          }
        }, [active ? _c('span', {
          staticClass: "material-icons-outlined primary--text"
        }, [_vm._v("favorite")]) : _c('span', {
          staticClass: "material-icons-outlined grey--text text--darken-4"
        }, [_vm._v("favorite_border")])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }