var render = function render(){
  var _vm$user, _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--product"
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('section', {
    staticClass: "product-intro"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-14px mb-sm-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('product-view-imgs', _vm._b({}, 'product-view-imgs', {
    product: _vm.product
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('product-view-info', _vm._b({
    scopedSlots: _vm._u([{
      key: "form",
      fn: function () {
        return [_c('product-form-related-group-items', _vm._b({}, 'product-form-related-group-items', {
          product: _vm.product
        }, false)), _c('info-section', {
          staticClass: "txt txt--xs"
        }, [_c('product-form-options', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'product-form-options', {
          product: _vm.product
        }, false)), _c('product-form-carts', {
          attrs: {
            "clearable": _vm.isProductCartClearable
          },
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        })], 1), _c('product-form-shipping', _vm._b({
          model: {
            value: _vm.shippingOption,
            callback: function ($$v) {
              _vm.shippingOption = $$v;
            },
            expression: "shippingOption"
          }
        }, 'product-form-shipping', {
          product: _vm.product
        }, false))];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('product-form-price', _vm._b({}, 'product-form-price', {
          carts: _vm.carts
        }, false)), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--xs ma-md-n6px"
        }, [_c('v-col', {
          staticClass: "pa-md-6px",
          attrs: {
            "cols": "12",
            "sm": "4"
          }
        }, [_c('inquire-form', _vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "v-size--xx-large",
                attrs: {
                  "block": ""
                }
              }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
                loading: _vm.loading
              }), false), on), [_vm._v("제품문의")])];
            }
          }])
        }, 'inquire-form', {
          product: _vm.product
        }, false))], 1), _c('v-col', {
          staticClass: "pa-md-6px",
          attrs: {
            "cols": "12",
            "sm": "4"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.addToCart
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, {
          loading: _vm.loading
        }), false), [_vm._v("장바구니")])], 1), _c('v-col', {
          staticClass: "pa-md-6px",
          attrs: {
            "cols": "12",
            "sm": "4"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.buyNow
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, {
          loading: _vm.loading
        }), false), [_vm._v("바로구매")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, 'product-view-info', {
    product: _vm.product
  }, false))], 1)], 1)], 1), _c('page-section', {
    staticClass: "pb-0"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": `${((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) || '회원'}님이 가장 많이 구매하시는 제품`
    }
  }), _c('shop-product-ai', _vm._b({}, 'shop-product-ai', {
    product: _vm.product
  }, false))], 1), _c('section', {
    staticClass: "product-details"
  }, [_c('product-view-nav', _vm._b({
    scopedSlots: _vm._u([{
      key: "form",
      fn: function () {
        return [_c('product-form-related-group-items', _vm._b({}, 'product-form-related-group-items', {
          product: _vm.product
        }, false)), _c('product-form-options', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'product-form-options', {
          product: _vm.product
        }, false)), _c('product-form-shipping', _vm._b({
          model: {
            value: _vm.shippingOption,
            callback: function ($$v) {
              _vm.shippingOption = $$v;
            },
            expression: "shippingOption"
          }
        }, 'product-form-shipping', {
          product: _vm.product
        }, false))];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('product-form-carts', {
          attrs: {
            "clearable": _vm.isProductCartClearable
          },
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }), _c('product-form-price', _vm._b({
          attrs: {
            "small": ""
          }
        }, 'product-form-price', {
          carts: _vm.carts
        }, false)), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--xs ma-md-n6px"
        }, [_c('v-col', {
          staticClass: "pa-md-6px",
          attrs: {
            "cols": "12",
            "sm": "4"
          }
        }, [_c('inquire-form', _vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "block": ""
                }
              }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
                loading: _vm.loading
              }), false), on), [_vm._v("제품문의")])];
            }
          }])
        }, 'inquire-form', {
          product: _vm.product
        }, false))], 1), _c('v-col', {
          staticClass: "pa-md-6px",
          attrs: {
            "cols": "12",
            "sm": "4"
          }
        }, [_c('v-btn', _vm._b({
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.addToCart
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, {
          loading: _vm.loading
        }), false), [_vm._v("장바구니")])], 1), _c('v-col', {
          staticClass: "pa-md-6px",
          attrs: {
            "cols": "12",
            "sm": "4"
          }
        }, [_c('v-btn', _vm._b({
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.buyNow
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, {
          loading: _vm.loading
        }), false), [_vm._v("바로구매")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, 'product-view-nav', {
    product: _vm.product,
    summary: _vm.summary
  }, false)), _c('div', {
    staticClass: "product-details__content pt-20px pt-md-40px",
    attrs: {
      "id": "detail"
    }
  }, [_c('section', {
    staticClass: "product-sub",
    domProps: {
      "innerHTML": _vm._s((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.content)
    }
  })]), _c('product-view-others')], 1), _c('product-view-mobile-bottom-sheet', {
    scopedSlots: _vm._u([{
      key: "form",
      fn: function () {
        return [_c('product-form-related-group-items', _vm._b({}, 'product-form-related-group-items', {
          product: _vm.product
        }, false)), _c('product-form-options', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'product-form-options', {
          product: _vm.product
        }, false)), _c('product-form-shipping', _vm._b({
          model: {
            value: _vm.shippingOption,
            callback: function ($$v) {
              _vm.shippingOption = $$v;
            },
            expression: "shippingOption"
          }
        }, 'product-form-shipping', {
          product: _vm.product
        }, false)), _c('product-form-carts', {
          attrs: {
            "clearable": _vm.isProductCartClearable
          },
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('product-form-price', _vm._b({}, 'product-form-price', {
          carts: _vm.carts
        }, false)), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', {
          staticClass: "pa-md-6px",
          attrs: {
            "cols": "6"
          }
        }, [_c('inquire-form', _vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "v-size--xx-large",
                attrs: {
                  "block": ""
                }
              }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
                loading: _vm.loading
              }), false), on), [_vm._v("제품문의")])];
            }
          }])
        }, 'inquire-form', {
          product: _vm.product
        }, false))], 1), _c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.addToCart
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, _vm.$attrs, {
          loading: _vm.loading
        }), false), [_vm._v("장바구니")])], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.buyNow
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs, {
          loading: _vm.loading
        }), false), [_c('span', {
          staticClass: "white--text"
        }, [_vm._v("바로구매")])])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('product-form-modal-cart', {
    ref: "modal-cart"
  })], 1), _c('product-view-console')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }