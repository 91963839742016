var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    class: 'swiper swiper--' + _vm.slideNum,
    attrs: {
      "options": _vm.swiperOptions[_vm.slideNum]
    }
  }, [_vm._t("default"), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('button', {
    staticClass: "swiper-button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('button', {
    staticClass: "swiper-button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }