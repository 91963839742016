<template>
    <slide-primary :slideNum="2">
        <template v-for="item in items">
            <swiper-slide :key="item._id">
                <product-list-item :value="item" slide />
            </swiper-slide>
        </template>
    </slide-primary>
</template>

<script>
import { initProduct } from "@/assets/variables";

import SlidePrimary from "@/components/dumb/slide-primary.vue";
import ProductListItem from "@/components/client/shop/products/list/product-list-item.vue";

export default {
    components: {
        SlidePrimary,
        ProductListItem,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    computed: {
        items() {
            return this.product?.addOns;
        },
    },
};
</script>

<style></style>
