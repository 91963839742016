<template>
    <swiper :class="'swiper swiper--'+slideNum" :options="swiperOptions[slideNum]">
        <slot />
        <div class="swiper-control" slot="pagination">
            <button class="swiper-button-prev"><v-icon>mdi-chevron-left</v-icon></button>
            <button class="swiper-button-next"><v-icon>mdi-chevron-right</v-icon></button>
        </div>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    props: {
        slideNum: { type: Number, default: "" },
    },
    data() {
        return {
            swiperOptions: {},
        };
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    created() {
        this.swiperOptions[this.slideNum] = {
            allowTouchMove: true,
            centeredSlides: false,
            slidesPerView: 2,
            spaceBetween: 16,
            speed: 500,
            navigation: {
                nextEl: `.swiper--${this.slideNum} .swiper-button-next`,
                prevEl: `.swiper--${this.slideNum} .swiper-button-prev`,
            },
            breakpoints: {
                768: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                },
            },
        };
    },
};
</script>

<style>

</style>