<template>
    <vertical-form-table v-model="form" v-bind="{ mode, items }" @input="emit" />
</template>

<script>
import { mapState } from "vuex";
import { attrs_input, initOrder } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const items = [
    {
        key: "paymentUserName",
        term: "본인인증 이름",
        type: "text",
        required: true,
        ...attrs_input,
    },
    {
        key: "paymentUserPhone",
        term: "본인인증 연락처",
        type: "phone",
        required: true,
        ...attrs_input,
    },
];

export default {
    components: {
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initOrder },
    },
    data: () => ({
        form: initOrder(),

        items,
    }),
    computed: {
        ...mapState(["user"]),
        mode() {
            const { nicepayUserName, nicepayUserPhone } = this.user || {};
            return [nicepayUserName, nicepayUserPhone].some((value) => !value) ? "form" : "view";
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        user() {
            this.init();
        },
    },
    methods: {
        init() {
            this.$emit("input", initOrder({ ...this.value, paymentUserKey: this.user?.nicepayUserKey || null, paymentUserName: this.user?.nicepayUserName || null, paymentUserPhone: this.user?.nicepayUserPhone || null }));
        },
        sync() {
            this.form = initOrder(this.value);
        },
        emit() {
            this.$emit("input", initOrder(this.form));
        },
    },
};
</script>
