var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vertical-form-table', _vm._b({
    attrs: {
      "mode": "mask"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.sender,
      callback: function ($$v) {
        _vm.sender = $$v;
      },
      expression: "sender"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }