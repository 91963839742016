<template>
    <u-dialog v-model="shows" :title="'타겟 ' + coupon.usage.target.typeText + ' 할인 쿠폰'" width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <page-section class="page-section--xs pt-0">
            <div class="txt txt--xs">{{ coupon.usage.target.priceText }} 적용가능</div>
        </page-section>

        <page-section class="page-section--xs py-0">
            <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.ALLITEMS.value">
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px txt txt--xs">
                        <div class="txt txt--xs txt--dark font-weight-bold mb-8px">적용가능 제품</div>
                        <div>전체 제품 사용가능</div>
                    </div>
                </v-card>
            </template>
            <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.PRODUCTS.value">
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px txt txt--xs">
                        <div class="txt txt--xs txt--dark font-weight-bold mb-8px">적용가능 제품</div>
                        <div>제품 상세 별도표기</div>
                    </div>
                </v-card>
            </template>
            <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.DISPLAYS.value">
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px txt txt--xs">
                        <item-dialog-displays v-bind="{ coupon }" />
                    </div>
                </v-card>
            </template>
            <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.EXHIBITS.value">
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px txt txt--xs">
                        <item-dialog-exhibits v-bind="{ coupon }" />
                    </div>
                </v-card>
            </template>
            <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.CATEGORY.value">
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px txt txt--xs">
                        <item-dialog-categories v-bind="{ coupon }" />
                    </div>
                </v-card>
            </template>
        </page-section>
    </u-dialog>
</template>

<script>
import { initShopCoupon, USAGE_TARGET_TYPES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ItemDialogDisplays from "./item-dialog-displays.vue";
import ItemDialogExhibits from "./item-dialog-exhibits.vue";
import ItemDialogCategories from "./item-dialog-categories.vue";

export default {
    components: {
        PageSection,
        UDialog,
        ItemDialogDisplays,
        ItemDialogExhibits,
        ItemDialogCategories,
    },
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        shows: false,
        USAGE_TARGET_TYPES,
    }),
};
</script>

<style></style>
