<template>
    <div>
        <div class="txt txt--xs txt--dark font-weight-bold mb-8px">적용가능 제품전시</div>
        <v-row class="row--sm">
            <v-col v-for="item in items" :key="item._id" cols="auto">
                <router-link :to="{ path: '/shop/products', query: { groups: item.value } }">· {{ item.text }}</router-link>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { initShopCoupon } from "@/assets/variables";

export default {
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    computed: {
        ...mapGetters(["displayCodes"]),
        items() {
            return this.coupon.usage.target.displayCodes.map((code) => this.displayCodes.find(({ value }) => value == code));
        },
    },
};
</script>

<style></style>
