import { render, staticRenderFns } from "./order-easy.vue?vue&type=template&id=d4c39af6&"
import script from "./order-easy.vue?vue&type=script&lang=js&"
export * from "./order-easy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports