<template>
    <u-dialog v-model="shows" title="쿠폰 사용하기" width="1080" maxWidth="1080" eager v-bind="{ loading }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs: { ...attrs, loading, disabled }, count_total: coupons.length, count_usable: coupons.length, on }" />
        </template>
        <v-row class="mx-n3 my-3">
            <v-col cols="8" class="pa-3">
                <v-divider class="grey darken-4" style="border-width: 2px 0 0 !important" />
                <v-data-table v-model="selected" v-bind="{ headers, items, loading }" show-select single-select hide-default-footer @click:row="(item, { isSelected, select }) => select(!isSelected)" item-key="coupon._id" sort-by="amount" sort-desc hide-default-header>
                    <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>
                    <template #[`item.coupon`]="{ value }">
                        <item-chip :coupon="value" />
                        <item-name :coupon="value" />
                        <item-price :coupon="value" />
                        <item-desc :coupon="value" mode="user-coupons" class="font-size-12 grey--text" />
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="4" class="pa-3">
                <order-coupons-prices v-bind="{ carts, selected }" :order="value" />
            </v-col>
        </v-row>

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="btn_tertiary" block class="v-size--xx-large" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="btn_primary" block class="v-size--xx-large" @click="save">저장하기</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";

import { mapState } from "vuex";
import { btn_primary, btn_tertiary, initOrder, USAGE_TARGET_PRICES } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import OrderCouponsPrices from "./order-coupons-prices.vue";
import ItemChip from "../../coupons/item/item-chip.vue";
import ItemName from "../../coupons/item/item-name.vue";
import ItemPrice from "../../coupons/item/item-price.vue";
import ItemDesc from "../../coupons/item/item-desc.vue";

const headers = [
    { text: "할인금액", value: "amount", formatter: (value) => (value ? `${value?.format?.() || value}원` : "-") },
    { text: "쿠폰", value: "coupon" },
];

export default {
    components: {
        UDialog,
        OrderCouponsPrices,
        ItemChip,
        ItemName,
        ItemPrice,
        ItemDesc,
    },
    props: {
        value: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
    },
    data: () => ({
        coupons: [],
        selected: [],

        headers,
        btn_primary,
        btn_tertiary,

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapState(["accessToken"]),
        items() {
            return [...(this.coupons || [])].map((coupon) => ({ coupon, amount: this.$getCouponPrice(this.carts, [coupon]) }));
        },
        disabled() {
            return !this.loading && !this.items?.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        accessToken() {
            this.init();
        },
    },
    methods: {
        sync() {
            const otherCoupons = this.value.coupons.filter((coupon) => coupon.usage.target.price != USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value);
            const shippingCoupons = this.value.coupons.filter(({ _id }) => !otherCoupons.some((item) => item._id == _id));

            this.selected = shippingCoupons.map((coupon) => ({ coupon, amount: this.$getCouponPrice(this.carts, [...otherCoupons].concat(coupon)) - this.$getCouponPrice(this.carts, otherCoupons) }));
        },
        emit() {
            const selected = this.selected.map(({ coupon }) => coupon);
            const coupons = this.value.coupons.filter((coupon) => coupon.usage.target.price != USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value).concat(...selected);
            this.$emit("input", initOrder({ ...this.value, coupons }));
        },

        async init() {
            if (!this.accessToken) {
                this.coupons = [];
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupons } = await api.v1.me.coupons.gets({ params: { ["usage.target.price"]: USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value } });

                this.coupons = coupons;
            } finally {
                this.loading = false;
                this.emit();
            }
        },
        save() {
            this.emit();
            this.shows = false;
        },
    },
};
</script>

<style></style>
