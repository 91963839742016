var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__content",
    attrs: {
      "id": "delivery"
    }
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "src": "/images/sub/mall/delivery-info.jpg",
      "max-width": "500",
      "aspect-ratio": 1100 / 2534
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }