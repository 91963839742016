var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "focus": function ($event) {
        _vm.focus = true;
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      },
      "click:clear": _vm.clear
    },
    scopedSlots: _vm._u([!_vm.focus ? {
      key: "prepend-inner",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "mt-n2 ml-n2",
          attrs: {
            "text": "",
            "icon": "",
            "tile": ""
          }
        }, [_c('icon-search', {
          staticClass: "icon-search--sm"
        })], 1)];
      },
      proxy: true
    } : null, _vm.focus ? {
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "mt-n2 mr-n2",
          attrs: {
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": _vm.search
          }
        }, [_c('icon-search', {
          staticClass: "icon-search--sm"
        })], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }