<template>
    <v-row align="center">
        <v-col cols="12" md="">
            <tit-wrap-subtitle titAlign="text-center text-md-left" class="mb-0">
                <template slot="tit">
                    <span class="tit">
                        <template v-if="$route.query.searchValue">
                            {{ $route.query.searchValue }}
                        </template>
                        <template v-else-if="category?.name">
                            {{ category?.name }}
                        </template>
                        <template v-else> Total </template>
                    </span>
                </template>
                <template slot="subTit">
                    <template v-if="$route.query.searchValue"> 키워드검색 </template>
                </template>
            </tit-wrap-subtitle>
        </v-col>
        <template v-if="!hideInfo">
            <v-col cols="12" md="auto" class="text-right">
                <span class="font-size-14 grey--text">
                    검색결과 <span class="primary--text">{{ summary.totalCount.format() }}</span> 개
                </span>
            </v-col>
            <v-col cols="12" md="auto">
                <product-list-sort-select />
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="d-md-none">
                <product-list-aside :categoryCode="$route.query.category" />
            </v-col>
        </template>
    </v-row>
</template>

<script>
import { initShopCategory } from "@/assets/variables";

import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import ProductListAside from "./product-list-aside.vue";
import ProductListSortSelect from "./sort/product-list-sort-select.vue";

export default {
    components: {
        TitWrapSubtitle,
        ProductListAside,
        ProductListSortSelect,
    },
    props: {
        category: { type: Object, default: initShopCategory },
        summary: { type: Object, default: () => ({ totalCount: 0 }) },
        hideInfo: { type: Boolean, default: false },
    },
};
</script>

<style></style>
