var render = function render(){
  var _vm$product, _vm$product$category, _vm$product2, _vm$product2$category, _vm$product3, _vm$product3$category, _vm$product4, _vm$product4$brand, _vm$product4$brand$la, _vm$product5, _vm$product5$brand, _vm$product5$brand$la;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-intro__info pl-lg-8px"
  }, [_c('info-section', {
    staticClass: "mt-sm-n8px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', [_c('info-groups', _vm._b({
    staticClass: "mb-4px mb-md-8px"
  }, 'info-groups', {
    product: _vm.product
  }, false)), _vm.product.labels.includes('new') ? [_c('v-chip', {
    staticClass: "mb-4px mb-md-8px",
    attrs: {
      "small": "",
      "dark": "",
      "color": "#3BAF2C"
    }
  }, [_vm._v(" new ")])] : _vm._e(), _vm.product.labels.includes('digital') ? [_c('v-chip', {
    staticClass: "mb-4px mb-md-8px",
    attrs: {
      "small": "",
      "dark": "",
      "color": "#8600DD"
    }
  }, [_vm._v(" digital ")])] : _vm._e(), _vm._v(" "), _c('br'), (_vm$product = _vm.product) !== null && _vm$product !== void 0 && (_vm$product$category = _vm$product.category) !== null && _vm$product$category !== void 0 && _vm$product$category.name ? [_c('v-chip', {
    staticClass: "mb-4px mb-md-8px",
    attrs: {
      "small": "",
      "dark": "",
      "color": ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$category = _vm$product2.category) === null || _vm$product2$category === void 0 ? void 0 : _vm$product2$category.color) || 'grey'
    }
  }, [_vm._v(" " + _vm._s((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$category = _vm$product3.category) === null || _vm$product3$category === void 0 ? void 0 : _vm$product3$category.name) + " ")])] : _vm._e(), _c('h3', {
    staticClass: "product-intro__name-text"
  }, [_vm._v(_vm._s(_vm.product.name))])], 2), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('info-like', _vm._b({}, 'info-like', {
    product: _vm.product
  }, false))], 1)], 1)], 1)], 1)], 1), (_vm$product4 = _vm.product) !== null && _vm$product4 !== void 0 && (_vm$product4$brand = _vm$product4.brand) !== null && _vm$product4$brand !== void 0 && (_vm$product4$brand$la = _vm$product4$brand.label) !== null && _vm$product4$brand$la !== void 0 && _vm$product4$brand$la.url ? _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--dark font-weight-medium w-md-80px"
  }, [_vm._v("브랜드")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": (_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : (_vm$product5$brand = _vm$product5.brand) === null || _vm$product5$brand === void 0 ? void 0 : (_vm$product5$brand$la = _vm$product5$brand.label) === null || _vm$product5$brand$la === void 0 ? void 0 : _vm$product5$brand$la.url,
      "max-width": "84",
      "aspect-ratio": 84 / 20,
      "contain": ""
    }
  })], 1)], 1)], 1) : _vm._e(), _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('info-shipping', _vm._b({}, 'info-shipping', {
    product: _vm.product
  }, false))], 1), _c('div', {
    staticClass: "d-none d-lg-block"
  }, [_vm._t("form"), _vm._t("actions")], 2), _vm._t("foot")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }