var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shows ? _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "left": "",
      "bottom": "",
      "x-large": "",
      "color": "white",
      "elevation": "4"
    }
  }, 'v-btn', {
    href: _vm.href
  }, false), [_c('v-row', [_c('v-col', [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-col', [_vm._v(" 제품 수정하기 ")])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }