<template>
    <vertical-table v-bind="{ items }" />
</template>

<script>
import { mapState } from "vuex";
import { initOrder, USAGE_TARGET_PRICES } from "@/assets/variables";

import VerticalTable from "@/components/dumb/vertical-table.vue";

export default {
    components: {
        VerticalTable,
    },
    props: {
        order: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
        selected: { type: Array, default: () => [] },
    },
    computed: {
        ...mapState(["islands", "setting"]),
        items() {
            const productPrice = this.$getProductPrice(this.carts);
            const discountPrice = this.$getDiscountPrice(this.carts);
            const deliveryPrice = this.$getDeliveryPrice(this.carts, this.order?.receiver, this.setting);
            const servicePrice = this.$getServicePrice(this.carts);
            const islandPrice = this.$getIslandPrice(this.carts, this.islands, this.order.receiver.postcode);
            const pointPrice = this.order.point;
            const coupons = this.order.coupons.filter((coupon) => coupon?.usage?.target?.price != USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value).concat(...this.selected);
            const couponPrice = this.$getCouponPrice(this.carts, coupons);
            const totalPrice = productPrice + servicePrice + deliveryPrice + islandPrice - discountPrice - pointPrice - couponPrice;

            return [
                { term: "제품금액", data: productPrice },
                { term: "제품할인", data: discountPrice },
                { term: "배송비", data: servicePrice + deliveryPrice },
                { term: "추가배송비", data: islandPrice },
                { term: "사용적립금", data: pointPrice },
                { term: "쿠폰할인", data: couponPrice },
                { term: "할인적용금액", data: totalPrice },
            ].map((item) => ({ ...item, data: `${item.data?.format?.() || item.data}원` }));
        },
    },
};
</script>

<style></style>
