<template>
    <v-simple-table class="v-data-table--default v-data-table--no-hover">
        <tbody>
            <tr>
                <td>
                    <v-currency-field v-model="point" v-bind="{ max, disabled }" outlined hide-details type="number" suffix="원" class="v-currency-field" @input="emit">
                        <template #append-outer>
                            <v-btn x-large outlined color="primary" v-bind="{ disabled }" @click="togglePointMax" class="min-w-md-110px">전체사용</v-btn>
                        </template>
                    </v-currency-field>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        max: { type: Number, default: 0 },
        value: { type: Number, default: 0 },
    },
    data: () => ({
        point: 0,
    }),
    computed: {
        ...mapState(["user"]),
        disabled() {
            return !this.user?.point;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.point = +this.value || 0;
        },
        emit() {
            this.$emit("input", this.point);
        },
        togglePointMax() {
            if (this.max == this.point) this.point = 0;
            else this.point = this.max;
            this.emit();
        },
    },
};
</script>
