<template id="modal-template">
    <u-dialog v-model="shows" name="modal" title="문의하기">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <tit-wrap-x-small title="문의 제목"></tit-wrap-x-small>
        <v-text-field v-model="inquire.subject" dense outlined hide-details placeholder="문의 제목을 입력해주세요." />

        <page-section class="page-section--xs pb-0">
            <tit-wrap-x-small title="문의 내용"></tit-wrap-x-small>
            <v-textarea v-model="inquire.content" dense outlined hide-details placeholder="문의 내용을 입력해주세요." />
            <div class="pt-8px">
                <v-checkbox v-model="inquire.secret" v-bind="{ ...checkbox_primary }" :value="true" hide-details label="비공개" />
            </div>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <tit-wrap-x-small title="이미지 등록"></tit-wrap-x-small>
            <form-files v-model="inquire.images" />
        </page-section>

        <template #actions>
            <v-row justify="center" class="row--sm">
                <v-col cols="12" md="auto">
                    <v-btn v-bind="{ ...btn_primary, loading }" class="v-size--xx-large w-100 min-w-sm-200px" @click="submit">문의하기</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import { btn_primary, checkbox_primary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import FormFiles from "@/components/client/dumb/form-files.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    props: {
        product: { type: Object, default: null },
    },
    components: {
        PageSection,
        UDialog,
        FormFiles,
        TitWrapXSmall,
    },
    data: () => ({
        btn_primary,
        checkbox_primary,

        shows: false,
        loading: false,

        inquire: {
            subject: null,
            content: null,
            secret: false,
            images: [],
        },
    }),
    methods: {
        async submit() {
            try {
                if (this.loading) return;
                else this.loading = true;

                if (!this.accessToken) throw new Error("로그인 이후 이용가능합니다");

                var { inquire } = await this.postInquire(
                    Object.assign(
                        {
                            _product: this.product._id,
                        },
                        this.inquire
                    )
                );

                for (var i in this.inquire.images) {
                    if (this.inquire.images[i]) await this.postFile(inquire._id, i, this.inquire.images[i]);
                }

                alert("문의가 완료되었습니다");
                this.shows = false;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                if (this.loading) this.loading = false;
            }
        },
        postInquire(inquire) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/me/inquires", inquire);

                    resolve(res.data);
                } catch (error) {
                    reject(error);
                }
            });
        },
        postFile(_inquire, index, file) {
            var data = new FormData();
            data.append("image", file);
            var headers = { "Content-Type": "multipart/formdata" };
            return this.$http.post(`/api/v1/me/inquires/${_inquire}/images/${index}`, data, { headers });
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
