<template>
    <v-list-group v-if="category && (category.children || []).length != 0" :value="_selecteds.includes(category._id)" no-action sub-group active-class="product-category-list--active">
        <template v-slot:activator>
            <v-list-item-title>{{ category.name }}</v-list-item-title>
        </template>

        <v-list-item :class="{ 'v-list-item--exact-active': isOn }" @click="$emit('setCategory', { ...category })">
            <v-list-item-title>Total</v-list-item-title>
        </v-list-item>

        <slot />
    </v-list-group>
    <v-list-item v-else :class="{ 'v-list-item--exact-active': isOn }" @click.prevent.stop="$emit('setCategory', { ...category })">
        <v-list-item-title>{{ category.name }}</v-list-item-title>
    </v-list-item>
</template>

<script>
export default {
    props: {
        category: { type: Object, default: null },
        _selecteds: { type: Array, default: () => [] },
    },
    computed: {
        isOn() {
            return this.$route.query.category == this.category.code;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep{
    .v-list-group__items{
        padding-left: 6px;
    }
}
</style>
