var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('slide-primary', {
    attrs: {
      "slideNum": 2
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item._id
    }, [_c('product-list-item', {
      attrs: {
        "value": item,
        "slide": ""
      }
    })], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }