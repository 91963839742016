var render = function render(){
  var _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-intro__thumb pr-md-12px pr-lg-38px"
  }, [_c('v-card', {
    staticClass: "product-intro__thumb-main",
    attrs: {
      "rounded": "",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "square-img",
    style: `background-image:url(${_vm.imgUrl})`
  })]), _c('div', {
    staticClass: "pt-16px pt-sm-20px"
  }, [_c('div', {
    staticClass: "product-intro-thumb-slide ma-n10px"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.images, function (image, index) {
    return [_c('swiper-slide', {
      key: `thumb-${index}`,
      staticClass: "pa-10px"
    }, [_c('v-card', {
      staticClass: "product-intro__thumb-button overflow-hidden",
      attrs: {
        "elevation": "4",
        "role": "button",
        "aria-pressed": index == _vm.selectedIndex
      },
      on: {
        "click": function ($event) {
          _vm.selectedIndex = index;
        }
      }
    }, [image ? _c('div', {
      staticClass: "square-img",
      style: `background-image:url(${image === null || image === void 0 ? void 0 : image.url})`
    }) : _vm._e()])], 1)];
  }), _c('div', {
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('div', {
    staticClass: "swiper-button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)])], 2)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }