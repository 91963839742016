<template>
    <v-row class="product-items">
        <product-list-item v-for="(product, index) in products" :key="index" :value="product" />
    </v-row>
</template>

<script>
import api from "@/api";

import ProductListItem from "./product-list-item.vue";

export default {
    props: {
        page: { type: [Number, String], default: 1 },
        limit: { type: [Number, String], default: 24 },
        params: { type: Object, default: () => ({}) },
    },
    components: {
        ProductListItem,
    },
    data: () => ({
        products: [],

        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        skip() {
            return (this.page - 1) * this.limit;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.loading = false;
            this.search();
        },
        loading() {
            this.$emit("setLoading", this.loading);
        },
        summary() {
            this.$emit("setSummary", { ...this.summary });
        },
    },
    methods: {
        async init() {
            this.products = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, products } = await api.v1.shop.products.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.products = products;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
