<template>
    <div v-bind="{ loading }">
        <div class="txt txt--xs txt--dark font-weight-bold mb-8px">적용가능 제품전시</div>
        <v-row class="row--sm">
            <v-col v-for="item in items" :key="item._id" cols="auto">
                <router-link :to="{ path: `/shop/exhibitions/${item._id}` }">· {{ item.title }}</router-link>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from "@/api";

import { initShopCoupon } from "@/assets/variables";

export default {
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        exhibitions: [],
        loading: false,
    }),
    computed: {
        items() {
            return [...this.exhibitions];
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "coupon._id"() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { exhibitions } = await api.v1.shop.exhibitions.gets({
                    params: {
                        _exhibition: this.coupon.usage.target._exhibitions,
                    },
                });
                this.exhibitions = exhibitions;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
